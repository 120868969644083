import { LazyQueryExecFunction } from "@apollo/client"
import {
  Exact,
  OrderListFragment,
  TrackingLabelQuery,
  useTrackingLabelLazyQuery,
} from "@gen/graphql"
import { Button, Card, Typography } from "@northvolt/ui"
import {
  DownloadButton,
  OldStyledDataGrid,
  StatusChip,
  StyledDataGrid,
  sumOrderGrossWeight,
  sumOrderNetWeight,
} from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useState } from "react"
import { FilterPanel } from "./FilterPanel"

type PickupOrderListProps = {
  orders: OrderListFragment[]
}

const sumOrderItems = (order: OrderListFragment): number => order.boxes.length

const useGetTrackingLabel = (
  orderID: string,
): LazyQueryExecFunction<TrackingLabelQuery, Exact<{ orderID: string }>> => {
  const [getTrackingLabelFn] = useTrackingLabelLazyQuery({
    variables: {
      orderID: orderID,
    },
  })
  return getTrackingLabelFn
}

export const PickupOrderList = ({ orders }: PickupOrderListProps): JSX.Element => {
  const navigate = useNavigate()

  const toggleDrawer = (orderId: string | undefined): void => {
    if (orderId) {
      navigate({
        to: "/overview/$orderId",
        params: {
          orderId: orderId,
        },
      })
    } else {
      navigate({
        to: "/overview",
      })
    }
  }
  const [filteredOrders, setFilteredOrders] = useState<OrderListFragment[]>(orders)

  return (
    <Card>
      <FilterPanel orders={orders} onFilterChange={setFilteredOrders} />
      <StyledDataGrid
        rowHeight={75}
        disableColumnMenu
        initialState={{ density: "comfortable" }}
        columns={[
          {
            field: "orderID",
            headerName: "Order ID",
            width: 200,
            sortable: false,
          },
          {
            field: "status",
            headerName: "Status",
            flex: 1,
            width: 200,
            sortable: false,
            renderCell: (params) => <StatusChip status={params.value} label={params.value} />,
          },
          {
            field: "createdBy",
            headerName: "Created By",
            flex: 1,
            width: 200,
            sortable: false,
          },
          {
            field: "pickupDate",
            headerName: "Pickup Date",
            flex: 1,
            width: 200,
            sortable: false,
          },
          {
            field: "pickupLocation",
            headerName: "Pickup Location",
            flex: 1,
            width: 400,
            sortable: false,
            renderCell: (params) => {
              return <OldStyledDataGrid>{params.value}</OldStyledDataGrid>
            },
          },
          {
            field: "orderContent",
            headerName: "Order Content",
            flex: 1,
            width: 200,
            sortable: false,
            renderCell: (params) => {
              return (
                <OldStyledDataGrid>
                  <Typography>{params.value} packages</Typography>
                  <Button
                    variant="text"
                    onClick={() => {
                      toggleDrawer(params.row.id)
                    }}
                  >
                    Show more
                  </Button>
                </OldStyledDataGrid>
              )
            },
          },
          {
            field: "netWeight",
            headerName: "Net Weight",
            flex: 1,
            width: 200,
            sortable: false,
          },
          {
            field: "grossWeight",
            headerName: "Gross Weight",
            flex: 1,
            width: 200,
            sortable: false,
          },
          {
            field: "trackingLabel",
            headerName: "Tracking Label",
            flex: 1,
            width: 200,
            sortable: false,
            renderCell: (params) => {
              if (params.row?.trackingLabelAvailable) {
                return (
                  <DownloadButton
                    getTrackingLabelFn={useGetTrackingLabel}
                    orderID={params.row.id}
                    type="simple"
                  />
                )
              }
            },
          },
        ]}
        rows={filteredOrders
          .map((order) => ({
            id: order.id,
            status: order.status,
            pickupDate: order.pickupDate,
            pickupLocation: order.pickupLocation?.displayName,
            orderContent: sumOrderItems(order),
            netWeight: sumOrderNetWeight(order),
            grossWeight: sumOrderGrossWeight(order),
            orderID: order.orderID,
            createdBy: order.createdBy?.firstName + " " + order.createdBy?.lastName,
            trackingLabelAvailable: order.trackingLabelAvailable,
          }))
          .reverse()}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            borderRadius: 0,
          },
        }}
      />
    </Card>
  )
}
