type Config = {
  cookieBaseName: string
  cookieDomain: string
  redirectURL: string
  apiURL: string
  mode: string
  cognito: {
    userPoolWebClientId: string
    oauthUri: string
  }
  approvedRoles: string[]
}

// eslint-disable-next-line no-restricted-globals
const baseDomain = `.${location.hostname.split(".").slice(1).join(".")}` // .revolt.northvolt.com or .preview.dev.northvolt.com or . (for localhost) @TODO what happens with localhost?

const cookieBaseName: { [key: string]: string } = {
  ".revolt.northvolt.com": import.meta.env.VITE_COOKIE_BASE_NAME,
  ".preview.dev.northvolt.com": "nv_auth_revolt_dev",
  ".": import.meta.env.VITE_COOKIE_BASE_NAME,
}

const cookieDomain: { [key: string]: string } = {
  ".revolt.northvolt.com": ".revolt.northvolt.com",
  ".preview.dev.northvolt.com": ".dev.northvolt.com",
  ".": "localhost",
}

const redirectURL: { [key: string]: string } = {
  ".revolt.northvolt.com": import.meta.env.VITE_REDIRECT_URL,
  ".preview.dev.northvolt.com": "https://login.revolt.dev.northvolt.com",
  ".": import.meta.env.VITE_REDIRECT_URL,
}

const apiURL: { [key: string]: string } = {
  ".revolt.northvolt.com": import.meta.env.VITE_API_URL,
  ".preview.dev.northvolt.com": "https://api.revolt.dev.northvolt.com/customer-portal/graphql",
  ".": import.meta.env.VITE_API_URL,
}

const userPoolWebClientId: { [key: string]: string } = {
  ".revolt.northvolt.com": import.meta.env.VITE_COGNITO_WEB_CLIENT_ID,
  ".preview.dev.northvolt.com": "75k46cpan22n82ngml0ajfbarp",
  ".": import.meta.env.VITE_COGNITO_WEB_CLIENT_ID,
}

const oauthUri: { [key: string]: string } = {
  ".revolt.northvolt.com": import.meta.env.VITE_COGNITO_OAUTH_URI,
  ".preview.dev.northvolt.com": "https://auth.revolt.dev.northvolt.com",
  ".": import.meta.env.VITE_COGNITO_OAUTH_URI,
}

export const config: Config = {
  // TODO once we have a proper division between nvlt.co and northvolt.co we can remove this and use the ENV
  cookieBaseName: cookieBaseName[baseDomain],
  cookieDomain: cookieDomain[baseDomain],
  redirectURL: redirectURL[baseDomain],
  apiURL: apiURL[baseDomain],
  mode: import.meta.env.MODE,
  cognito: {
    userPoolWebClientId: userPoolWebClientId[baseDomain],
    oauthUri: oauthUri[baseDomain],
  },
  approvedRoles: ["revolt_user", "user"],
}
