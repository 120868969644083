/* eslint-disable no-param-reassign */
import { BatteryStatus, OrderFragment, useFileQuery, useUploadFileMutation } from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "@lib/i18n"
import { zodEnum } from "@lib/zod"
import { Card, InputAdornment } from "@mui/material"
import {
  Alert,
  Box,
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@northvolt/ui"
import { FormTextField } from "@northvolt/ui/Form"
import {
  BatteryStatusLabel,
  CancelButton,
  CardActions,
  FileUpload,
  IconCarBattery,
  IconPlus,
  NumberInput,
} from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useState } from "react"
import { Controller, DefaultValues, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

const packSchema = z.object({
  packType: z.string(),
  applicationType: z.string().min(1),
  netWeight: z.number().min(1),
  batteryStatus: z.enum(zodEnum(Object.values(BatteryStatus))),
  boxType: z.string(),
  boxNetWeight: z.number().optional(),
  description: z.string(),
  fileIDs: z.array(z.string()),
})
export type ZodInputTypes = z.infer<typeof packSchema>

type EditPackSmallVolumeProps = {
  order: OrderFragment
  box: OrderFragment["boxes"][0]
  stepId: string
}

const useGetFile = (fileID: string): string | undefined => {
  const { data } = useFileQuery({
    variables: {
      fileId: fileID,
    },
  })
  return data?.file?.url
}

export const EditPackSmallVolume = ({
  order,
  box,
  stepId,
}: EditPackSmallVolumeProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [unknownPackType, setUnknownPackType] = useState(false)
  const [unknownBoxType, setUnknownBoxType] = useState(false)

  let fileIDs = []

  if (box.items[0].__typename === "Pack") {
    fileIDs = box.items[0].fileIDs
  }
  // TODO: make applicationType, description and grossWeight also get prefilled?
  const defaultValues: Required<DefaultValues<ZodInputTypes>> = {
    packType: box.items[0].displayName,
    applicationType: "",
    netWeight: box.items[0].netWeight,
    batteryStatus: BatteryStatus.Unknown,
    boxType: box.boxType.displayName,
    boxNetWeight: box.boxType.netWeight,
    description: "",
    fileIDs: fileIDs,
  }
  const methods = useForm<ZodInputTypes>({
    resolver: zodResolver(packSchema),
    mode: "onBlur",
    defaultValues,
  })
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid },
  } = methods

  const watchFileIDs: string[] = watch("fileIDs")

  const useUploadFile = () => {
    const [upload] = useUploadFileMutation()

    return async (file: File): Promise<boolean> => {
      const { data, errors: errs } = await upload({
        variables: {
          file,
        },
      })

      if (!errs && data) {
        setValue("fileIDs", [...watchFileIDs, data.upload.id])
      }
      return data !== undefined && errs === undefined
    }
  }

  const useRemoveFile = (id: string): void => {
    const updatedFileIds = watch("fileIDs").filter((fileID: string) => fileID !== id)
    setValue("fileIDs", updatedFileIds)
  }

  const onSubmit: SubmitHandler<ZodInputTypes> = (_data: ZodInputTypes) => {
    // Uppdate a här och lägg in en "navigate" för cancel
    // addPackMutation({
    //   variables: {
    //     input: {
    //       pickupOrderId: order.id,
    //       etag: order.etag,
    //       // Lägg till boxType och packType some text fält här istället för Idn
    //       // lägg in beskrivning och Application type också
    //       boxType: {
    //         displayName: data.boxType,
    //         netWeight: data.boxNetWeight,
    //       },
    //       packType: {
    //         displayName: data.packType,
    //       },
    //       netWeight: data.netWeight,
    //       batteryStatus: data.batteryStatus,
    //       count: data.copies,
    //     },
    //   },
    // }).then(() => {
    //   console.log("All done!")
    //   setType(undefined)
    // })
  }

  const uploadFile = useUploadFile()
  const hideUpload = watchFileIDs.length >= 4

  return (
    <Card>
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title="Add items to your pick-up" />
          <CardContent>
            <Box sx={{ width: "100%" }}>
              <Stack spacing={2}>
                <Alert severity="info">
                  {t("components.EditPackSmallVolume.packLimitDescription")}
                </Alert>

                <Box sx={{ bgcolor: "grey.800", padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography variant="headlineSmall">
                      <IconCarBattery /> Pack
                    </Typography>
                    <FormTextField
                      control={control}
                      name="packType"
                      label="Write Pack type (optional)"
                      disabled={unknownPackType}
                    />
                    <Checkbox
                      label="Unknown type"
                      onChange={(evt) => {
                        setUnknownPackType(evt.currentTarget.checked)
                        setValue("packType", evt.currentTarget.checked ? "Type unknown" : "")
                      }}
                    />
                    <FormTextField
                      control={control}
                      name="applicationType"
                      label="Application type (Required)"
                    />

                    <NumberInput
                      label={t("components.basics.netWeight")}
                      fullWidth
                      control={control}
                      defaultValue={undefined}
                      name="netWeight"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                    />

                    <Controller
                      control={control}
                      name="batteryStatus"
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography fontSize={16} sx={{ mr: 2 }}>
                              {t("components.EditPackSmallVolume.batteryStatus.title")}
                            </Typography>
                            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                            <RadioGroup {...field} row defaultValue={undefined}>
                              <BatteryStatusLabel
                                value={BatteryStatus.Green}
                                control={<Radio />}
                                label={t("components.basics.batteryStatus.green")}
                              />
                              <BatteryStatusLabel
                                value={BatteryStatus.Yellow}
                                control={<Radio />}
                                label={t("components.basics.batteryStatus.yellow")}
                              />
                            </RadioGroup>
                          </Stack>
                          {error && (
                            <Box>
                              <Alert severity="error">
                                {t("components.EditPackSmallVolume.batteryStatus.error")}
                              </Alert>
                            </Box>
                          )}
                        </>
                      )}
                    />
                    <Box>
                      <Typography variant="bodyMedium">
                        {t("components.FileUpload.pack_title")}
                      </Typography>
                      <Typography
                        variant="captionSmall"
                        component={"p"}
                        color="secondary"
                        sx={{ mb: 2 }}
                      >
                        {t("components.FileUpload.body")}
                      </Typography>
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap={2}>
                      {watchFileIDs.map((fileID) => (
                        <FileUpload
                          key={fileID}
                          fileID={fileID}
                          get={useGetFile}
                          upload={uploadFile}
                          remove={useRemoveFile}
                          allowUpload={true}
                          allowRemove={true}
                        />
                      ))}
                    </Box>
                    {!hideUpload && (
                      <FileUpload
                        get={useGetFile}
                        upload={uploadFile}
                        remove={useRemoveFile}
                        allowUpload={true}
                        allowRemove={true}
                      />
                    )}
                  </Stack>
                </Box>
                <Typography>Value: In what package type the item packaged in?</Typography>
                <FormTextField
                  control={control}
                  name="boxType"
                  label="Package type (optional)"
                  disabled={unknownBoxType}
                />
                <Checkbox
                  label="Package not available"
                  onChange={(evt) => {
                    setUnknownBoxType(evt.currentTarget.checked)
                    setValue("boxType", evt.currentTarget.checked ? "Type unknown" : "")
                  }}
                />
                <NumberInput
                  label={t("components.basics.grossWeight")}
                  fullWidth
                  control={control}
                  defaultValue={undefined}
                  name="boxNetWeight"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                  }}
                />
                <FormTextField
                  control={control}
                  name="description"
                  label="Describe your items (optional)"
                  multiline
                />
              </Stack>
            </Box>
          </CardContent>
          <CardActions>
            <CancelButton
              onClick={() => {
                navigate({
                  to: "/pickup-orders/$orderId/$stepId",
                  params: { orderId: order.id, stepId: stepId },
                })
              }}
            />
            <Button type="submit" color="secondary" disabled={!isValid} startIcon={<IconPlus />}>
              {t("components.AddBox.submit")}
            </Button>
          </CardActions>
        </form>
      </FormProvider>
    </Card>
  )
}
