import { AddItemSmallVolume } from "@components/PickupOrder/Components/AddItemSmallVolume/AddItemSmallVolume"
import { AddModuleSmallVolume } from "@components/PickupOrder/Components/AddModuleSmallVolume/AddModuleSmallVolume"
import { AddPackSmallVolume } from "@components/PickupOrder/Components/AddPackSmallVolume/AddPackSmallVolume"
import { OrderSummary } from "@components/PickupOrder/Components/OrderSummary/OrderSummary"
import { OrderFragment, useSubmitOrderMutation } from "@gen/graphql"
import { Grid } from "@northvolt/ui"
import { useNavigate, useSearch } from "@tanstack/react-router"
import { JSX } from "react"

type AddBoxSmallVolumeStepProps = {
  order: OrderFragment
  stepId: string
}

type InternalStep = "pack" | "module"

export const AddBoxSmallVolumeStep = ({
  order,
  stepId,
}: AddBoxSmallVolumeStepProps): JSX.Element => {
  const [submitOrder] = useSubmitOrderMutation()
  const navigate = useNavigate()
  const searchParams: { type: InternalStep | undefined } = useSearch({
    strict: false,
  })
  const setType = (type: InternalStep | undefined): void => {
    // Originally this code was just taking the search params.
    // But typescript gets sad when you don't include `to`.
    navigate({
      to: "/pickup-orders/$orderId/$stepId",
      search: {
        type: type,
      },
      params: {
        orderId: order.id,
        stepId: stepId,
      },
    })
  }

  const submit = (): void => {
    submitOrder({
      variables: {
        input: {
          pickupOrderId: order.id,
          etag: order.etag,
        },
      },
    }).then((result) => {
      navigate({
        to: "/pickup-orders/$orderId/$stepId",
        search: {
          submittedOrderId: result.data?.submitPickupOrder.id,
        },
        params: {
          orderId: order.id,
          stepId: stepId,
        },
      })
    })
  }
  return (
    <Grid container spacing={2} data-testid="add-box-small-volume-step">
      <Grid sm={12} md={6}>
        {!searchParams.type && <AddItemSmallVolume orderId={order.id} setType={setType} />}
        {searchParams.type === "pack" && <AddPackSmallVolume order={order} setType={setType} />}
        {searchParams.type === "module" && <AddModuleSmallVolume order={order} setType={setType} />}
        {/* {adding && (
          <AddBox
            order={data.pickupOrder}
            boxTypes={data.boxTypes}
            moduleTypes={data.moduleTypes}
            packTypes={data.packTypes}
            setAdding={setAdding}
          />
        )} */}
      </Grid>
      <Grid sm={12} md={6}>
        <OrderSummary
          stepId={stepId}
          order={order}
          submit={searchParams.type ? undefined : submit}
        />
      </Grid>
    </Grid>
  )
}
