import { useAcceptPrivacyPolicyMutation, useProfilePageQuery } from "@gen/graphql"
import { config } from "@lib/config"
import { CloseIcon } from "@mantine/core"
import { Box, IconButton, Theme, Typography, useMediaQuery } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import {
  Card,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
} from "@northvolt/ui"
import { logout } from "@providers"
import {
  AppBar,
  CollapsableIconButton,
  Drawer,
  IconCodeBranch,
  IconHouse,
  IconPlus,
  appBarOffset,
} from "@shared"
import { Outlet, createRootRoute, useNavigate } from "@tanstack/react-router"
import { JSX, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const Root = (): JSX.Element => {
  const { t } = useTranslation()
  const themeRoot = useTheme()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const isDesktopView = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))

  // force creation of user in case it's missing
  // TODO this should possibly be moved to the backend and be done on login
  const { data, loading, error } = useProfilePageQuery()

  const toggleOpen = (): void => {
    setOpen(!open)
  }

  const doLogout = (): void => {
    logout(config.cookieBaseName, config.cookieDomain)
    navigate({ to: "/" })
  }

  useEffect(() => {
    setOpen(isDesktopView)
  }, [isDesktopView])
  const logoPath = data?.me.tenant
    ? `${data?.me.tenant.displayName.toLowerCase()}-${themeRoot.palette.mode}.svg`
    : undefined

  return (
    <>
      {!loading && !error && !data?.me?.privacyPolicyAccepted && <AcceptPrivacyPolicy />}
      <AppBar toggleOpen={toggleOpen} logout={doLogout} logoPath={logoPath} />
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Drawer open={open}>
          <>
            <CollapsableIconButton
              open={open}
              startIcon={<IconPlus />}
              variant="contained"
              sx={{ borderRadius: 10, height: "48px" }}
              onClick={() => {
                navigate({ to: "/pickup-orders" })
              }}
            >
              <span>{t("components.MainMenu.pickup")}</span>
            </CollapsableIconButton>
            <List>
              <ListItemButton
                onClick={() => {
                  navigate({
                    to: "/",
                  })
                }}
              >
                <ListItemIcon>
                  <IconHouse />
                </ListItemIcon>
                <ListItemText primary={t("components.MainMenu.home")} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate({
                    to: "/overview",
                  })
                }}
              >
                <ListItemIcon>
                  <IconCodeBranch />
                </ListItemIcon>
                <ListItemText primary={t("components.MainMenu.followUp")} />
              </ListItemButton>
            </List>
          </>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 4,
            mt: appBarOffset,
          }}
        >
          <Container>{!loading && !error && <Outlet />}</Container>
        </Box>
      </Box>
    </>
  )
}

export const Route = createRootRoute({
  component: () => <Root />,
})

const AcceptPrivacyPolicy = (): JSX.Element => {
  const { t } = useTranslation()
  const [accept] = useAcceptPrivacyPolicyMutation()
  return (
    <Modal open>
      <CenterCard>
        <CloseButton
          onClick={() =>
            accept({
              variables: {
                input: {
                  acceptedAt: new Date().toISOString(),
                },
              },
            }).then((result) => {
              if (result.data?.acceptPrivacyPolicy) {
                window.location.reload()
              }
            })
          }
        >
          <CloseIcon size="24px" />
        </CloseButton>
        <Stack p={4} gap={4}>
          <Typography variant="headlineLarge">{t("components.PrivacyPolicy.title")}</Typography>
          <Typography variant="bodyMedium">
            <span
              dangerouslySetInnerHTML={{
                __html: t("components.PrivacyPolicy.content", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </Typography>
        </Stack>
      </CenterCard>
    </Modal>
  )
}

const CenterCard = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  width: 32px;
  height: 32px;
  &:hover {
    background-color: darkred;
  }
`
