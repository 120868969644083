import { MantineProvider } from "@mantine/core"
import { Dropzone } from "@mantine/dropzone"
import styled from "@mui/system/styled"
import { Box, Button, IconButton, Typography, useSnackbar } from "@northvolt/ui"
import { ChangeEvent, JSX, useState } from "react"
import { useTranslation } from "react-i18next"
import { IconTrashCan } from "../../index"

type Get = (fileID: string) => string | undefined
type Upload = (file: File) => Promise<boolean>
type Remove = (fileID: string) => void

const defaultAllowedTypes = ["image/jpeg", "image/png", "imgage/gif"]

type FileUploadProps = {
  fileID?: string | undefined
  allowedTypes?: string[]
  get: Get
  allowUpload?: boolean
  allowRemove?: boolean
} & ({ allowUpload: true; upload: Upload } | { allowUpload?: false; upload?: undefined }) &
  ({ allowRemove: true; remove: Remove } | { allowRemove?: false; remove?: undefined })

export const FileUpload = ({
  fileID,
  allowedTypes,
  get,
  upload,
  remove,
  allowUpload = false,
  allowRemove = false,
}: FileUploadProps): JSX.Element => {
  const { t } = useTranslation("components")
  const InnerComponent = fileID ? (
    <FileExists fileID={fileID} get={get} allowRemove={allowRemove} remove={remove} />
  ) : allowUpload ? (
    <FileUploader allowedTypes={allowedTypes ?? defaultAllowedTypes} upload={upload!} />
  ) : (
    <Typography variant="bodyMedium" color="textSecondary">
      {t("FileUpload.no_file_exists")}
    </Typography>
  )

  return <Box>{InnerComponent}</Box>
}

type FileUploaderProps = {
  allowedTypes: string[]
  upload: Upload
}

const FileUploader = ({ allowedTypes, upload }: FileUploaderProps): JSX.Element => {
  const [uploading, setUploading] = useState(false)
  const updateSnackbar = useSnackbar()
  const { t } = useTranslation("components")

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    event.preventDefault()
    const selectedFiles = event.target.files
    if (selectedFiles) {
      for (const file of selectedFiles) {
        if (allowedTypes.includes(file.type)) {
          await handleUpload(file)
        } else {
          updateSnackbar({
            message: t("FileUpload.invalid_file_type"),
            severity: "error",
          })
        }
      }
    }
    event.target.value = ""
  }

  const handleUpload = async (file: File): Promise<void> => {
    setUploading(true)
    try {
      const success = await upload(file)
      if (!success) {
        updateSnackbar({
          message: t("FileUpload.file_upload_failed"),
          severity: "error",
        })
      }
      setUploading(false)
      // eslint-disable-next-line unused-imports/no-unused-vars
    } catch (e) {
      updateSnackbar({
        message: t("FileUpload.file_upload_failed"),
        severity: "error",
      })
      setUploading(false)
    }
  }

  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    for (const file of acceptedFiles) {
      if (allowedTypes.includes(file.type)) {
        await handleUpload(file)
      } else {
        updateSnackbar({
          message: t("FileUpload.invalid_file_type"),
          severity: "error",
        })
      }
    }
  }

  const [isDragActive, setIsDragActive] = useState(false)

  return (
    <Box mb={2} data-testid="file-upload-uploader" display="flex" justifyContent="center">
      <MantineProvider>
        <DropzoneStyle
          onDrop={onDrop}
          onDragEnter={() => setIsDragActive(true)}
          onDragLeave={() => setIsDragActive(false)}
          accept={allowedTypes}
          multiple={false}
        >
          <Typography variant="bodyMedium" color="textSecondary">
            {isDragActive ? t("FileUpload.drag_active") : t("FileUpload.drag_inactive")}
          </Typography>
          <input
            type="file"
            required
            onChange={handleFileChange}
            style={{ display: "none" }} // Hide the actual file input
            id="file-upload-input"
          />
          <label htmlFor="file-upload-input">
            <Button color="secondary" component="span" sx={{ width: "100%", maxWidth: "200px" }}>
              {uploading ? t("FileUpload.upload_button_active") : t("FileUpload.upload_button")}
            </Button>
          </label>
        </DropzoneStyle>
      </MantineProvider>
    </Box>
  )
}

const FileExists = ({
  fileID,
  get,
  allowRemove,
  remove,
}: {
  fileID: string
  get: Get
  allowRemove: boolean
  remove?: Remove
}): JSX.Element => {
  const [hoveredFileId, setHoveredFileId] = useState<string | null>(null)
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 })

  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement>): void => {
    setHoverPosition({ x: e.clientX, y: e.clientY })
  }
  const handleMouseEnter = (id: string): void => {
    setHoveredFileId(id)
  }
  const handleMouseLeave = (): void => {
    setHoveredFileId(null)
  }

  const fileUrl = get(fileID) ?? ""

  return (
    <Box display="flex" gap={2} flexWrap="wrap" justifyContent="center">
      <ImagePreview>
        <img
          src={fileUrl}
          alt="Uploaded file"
          onMouseMove={handleMouseMove}
          onMouseEnter={() => handleMouseEnter(fileID)}
          onMouseLeave={handleMouseLeave}
        />
        {hoveredFileId === fileID && (
          <img
            src={fileUrl}
            alt="Hovered file"
            className="hovered-image"
            style={{
              top: hoverPosition.y + "px", // Align the top-left corner
              left: hoverPosition.x + "px",
              display: "block", // Show the image when hovered
            }}
          />
        )}
        {allowRemove && remove && (
          <IconButton
            size="small"
            color="secondary"
            aria-label="Delete image"
            onClick={
              () => remove(fileID) // Clear the fileID - This does not actually delete the file from S3, but it will be removed from the order
            }
          >
            <IconTrashCan />
          </IconButton>
        )}
      </ImagePreview>
    </Box>
  )
}

const DropzoneStyle = styled(Dropzone)(
  ({ theme }) => `
  background: ${theme.palette.grey[800]};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px dashed ${theme.palette.grey[400]};
  padding: ${theme.spacing(3)};
  text-align: center;
  width: 100%;
  justify-content: center;
  min-height: 100px;
  `,
)

const ImagePreview = styled("div")(
  ({ theme }) => `
  position: relative;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid ${theme.palette.grey[800]};
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .hovered-image {
    border: 1px solid ${theme.palette.grey[800]};
    position: fixed;
    z-index: 100;
    pointer-events: none;
    transform: none;
    width: auto;
    height: auto;
    max-width: 600px;
    max-height: 600px;
  }
  button {
    position: absolute;
    top: 5px;
    right: 4px;
  }
`,
)
