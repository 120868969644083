import { FormControlLabel } from "@mui/material"
import { styled } from "@mui/material/styles"
import { getColorFromStatus } from "../../lib/typography"

type BatteryStatus = "Green" | "Yellow" | "Red" | "Unknown"

export const BatteryStatusLabel = styled(FormControlLabel)<{ value: BatteryStatus }>(
  ({ theme, value }) => `
  border: 1px solid ${theme.palette.grey[600]};
  border-radius: 50px;
  position: relative;

  &::after {
    content: "";
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: block;
    margin: 10px;
    background: ${theme.palette[getColorFromStatus(value)].main};
  }
  `,
)
