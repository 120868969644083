import {
  IconDefinition,
  faArrowDownToLine,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBox,
  faBoxesStacked,
  faCarBattery,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClipboardListCheck,
  faCodeBranch,
  faHouse,
  faLocationDot,
  faMinus,
  faPen,
  faPlus,
  faSearch,
  faTrashCan,
  faTruckFast,
  faUser,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties, JSX } from "react"

type IconProps = {
  onClick?: () => void
  style?: CSSProperties
}

export const Icon = ({
  icon,
  onClick,
  style,
}: IconProps & { icon: IconDefinition }): JSX.Element => (
  <FontAwesomeIcon icon={icon} onClick={onClick} style={style} />
)

export const IconPen = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faPen} onClick={onClick} style={style} />
)
export const IconBox = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faBox} onClick={onClick} style={style} />
)
export const IconBoxesStacked = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faBoxesStacked} onClick={onClick} style={style} />
)
export const IconArrowLeft = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faArrowLeft} onClick={onClick} style={style} />
)
export const IconArrowRight = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faArrowRight} onClick={onClick} style={style} />
)
export const IconMinus = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faMinus} onClick={onClick} style={style} />
)
export const IconPlus = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faPlus} onClick={onClick} style={style} />
)
export const IconCarBattery = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faCarBattery} onClick={onClick} style={style} />
)
export const IconHouse = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faHouse} onClick={onClick} style={style} />
)
export const IconTrashCan = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faTrashCan} onClick={onClick} style={style} />
)
export const IconLocationDot = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faLocationDot} onClick={onClick} style={style} />
)
export const IconXmark = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faXmark} onClick={onClick} style={style} />
)
export const IconHamburger = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faBars} onClick={onClick} style={style} />
)
export const IconUser = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faUser} onClick={onClick} style={style} />
)
export const IconChevronLeft = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faChevronLeft} onClick={onClick} style={style} />
)
export const IconChevronRight = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faChevronRight} onClick={onClick} style={style} />
)
export const IconCodeBranch = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faCodeBranch} onClick={onClick} style={style} />
)
export const IconCheckmark = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faCheckCircle} onClick={onClick} style={style} />
)
export const IconTruck = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faTruckFast} onClick={onClick} style={style} />
)
export const IconClipBoard = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faClipboardListCheck} onClick={onClick} style={style} />
)
export const IconSearch = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faSearch} onClick={onClick} style={style} />
)
export const IconFileDownload = ({ onClick, style }: IconProps): JSX.Element => (
  <Icon icon={faArrowDownToLine} onClick={onClick} style={style} />
)
