import { UserFragment, useProfilePageQuery } from "@gen/graphql"
import { i18n } from "@lib/i18n"
import { Card, CardContent } from "@northvolt/ui"
import { Loader, Profile, RouteError } from "@shared"
import { createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type ProfilePageProps = {
  me: UserFragment | undefined
}

const ProfilePage = ({ me }: ProfilePageProps): JSX.Element => {
  if (!me) {
    return (
      <Card>
        <CardContent>Couldn't fetch the user information</CardContent>
      </Card>
    )
  }

  return <Profile user={me} i18n={i18n} />
}

const RouteComponent = (): JSX.Element => {
  const { data, loading, error } = useProfilePageQuery({})
  const { t } = useTranslation()

  if (loading) {
    return <Loader />
  }

  if (error || !data) {
    return <RouteError error={error || Error(t("errors.unknown"))} />
  }
  return <ProfilePage me={data.me} />
}

/**
 * Example of how we could use the Route component.
 * This doesn't really work when we rely on cached data,
 * since the component data isn't updated when using qglClient directly.

export const Route = createFileRoute("/profile")({
  component: () => <RouteComponent />,
  pendingComponent: () => <Loader />,
  errorComponent: ({ error }) => <RouteError error={error} />,
  loader: async () =>
    gqlClient(config).query({
      query: ProfilePageDocument,
      variables: {},
    }),
  })
*/
export const Route = createFileRoute("/profile")({
  component: () => <RouteComponent />,
})
