import { Box, Loader as NVLoader } from "@northvolt/ui"
import { JSX } from "react"

export const Loader = ({
  inline = false,
  full,
}: {
  inline?: boolean
  full?: boolean
}): JSX.Element => {
  return inline ? (
    <NVLoader />
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={full ? "100vh" : "100%"}
    >
      <NVLoader />
    </Box>
  )
}
