import { styled } from "@mui/material/styles"
import { SxProps } from "@mui/system"
import { Box } from "@northvolt/ui"
import { JSX } from "react"
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  UseFormSetValue,
  useController,
} from "react-hook-form"
import { IconMinus, IconPlus } from "../Icons/Icons"

export const NumberSelect = <T extends FieldValues, U extends FieldPath<T>>({
  control,
  setValue,
  name,
  minNumber,
  maxNumber,
  sx,
}: {
  control: Control<T>
  setValue: UseFormSetValue<T>
  name: U
  minNumber?: number
  maxNumber?: number
  sx?: SxProps
}): JSX.Element => {
  const { field } = useController({ control, name })

  return (
    <StyledInputRoot sx={sx}>
      <StyledButton
        onClick={(evt) => {
          evt.preventDefault()
          if (minNumber !== undefined && Number(field.value) <= minNumber) {
            return
          }
          setValue(name, (Number(field.value) - 1) as PathValue<T, U>, { shouldValidate: true })
        }}
      >
        <IconMinus />
      </StyledButton>
      <StyledInput
        aria-label="Demo number input"
        placeholder="Type a number…"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      />
      <StyledButton
        className="increment"
        data-testid="increment"
        onClick={(evt) => {
          evt.preventDefault()
          if (maxNumber !== undefined && Number(field.value) >= maxNumber) {
            return
          }
          setValue(name, (Number(field.value) + 1) as PathValue<T, U>, { shouldValidate: true })
        }}
      >
        <IconPlus />
      </StyledButton>
    </StyledInputRoot>
  )
}

const StyledButton = styled("button")(
  ({ theme }) => `
  background: ${theme.palette.mode === "dark" ? theme.palette.grey[100] : theme.palette.grey[900]};
  color: ${theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.grey[100]};
  border: none;
  border-radius: 50px 0 0 50px;
  height: 48px;
  padding: 0 10px;
  &.increment {
    order: 1;
  border-radius: 0 50px 50px 0;
  }
  `,
)

const StyledInput = styled("input")(
  ({ theme }) => `
  background: ${theme.palette.mode === "dark" ? theme.palette.grey[100] : theme.palette.grey[900]};
  color: ${theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.grey[100]};
  width: 60px;
  text-align: center;
  border: none;
  line-height: 46px;
  outline: none;
`,
)

const StyledInputRoot = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid ${theme.palette.mode === "dark" ? theme.palette.grey[300] : theme.palette.grey[400]};
  color: ${theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.grey[100]};

  `,
)
