import { Overview } from "@components/Overview/Overview"
import { Typography } from "@northvolt/ui"
import { Loader, RouteError } from "@shared"
import { Outlet, createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"
import { OrderListFragment, usePickupOrdersQuery } from "../gen/graphql"

const PickupOrderListComponent = ({
  orders,
}: {
  orders: OrderListFragment[] | undefined
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div>
      <Typography sx={{ mb: 3 }} variant="headlineMedium">
        {t("routes.overview.header")}
      </Typography>
      <Overview orders={orders ?? []} />
      <Outlet />
    </div>
  )
}

const RouteComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { data, error, loading } = usePickupOrdersQuery({
    fetchPolicy: "network-only",
  })

  if (loading) {
    return <Loader />
  }

  if (error || !data) {
    return <RouteError error={error || Error(t("errors.unknown"))} />
  }

  return <PickupOrderListComponent orders={data.pickupOrders} />
}

export const Route = createFileRoute("/overview")({
  component: () => <RouteComponent />,
})
