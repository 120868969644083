import { Box, NVThemeProvider } from "@northvolt/ui"
import { NVLocalizationProviderDayjs } from "@northvolt/ui/Dayjs"
import { ApolloProvider, AuthProvider } from "@providers"
import * as Sentry from "@sentry/browser"
import { Loader, MissingRole } from "@shared"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import { JSX } from "react"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "usehooks-ts"
import { routeTree } from "./gen/routeTree.gen"
import { config } from "./lib/config"
import { i18n } from "./lib/i18n"

// Create a new router instance
const router = createRouter({ routeTree })

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

export const App = (): JSX.Element => {
  const [currentLanguage] = useLocalStorage("language", "en-GB")
  const { t } = useTranslation()

  // Change language to english
  i18n.changeLanguage(currentLanguage)
  let dayJSLocale = "en-gb"
  switch (i18n.language) {
    case "de-DE":
      import("dayjs/locale/de")
      dayJSLocale = "de"
      break
    default:
      import("dayjs/locale/en-gb")
  }

  return (
    <ErrorBoundary fallbackRender={FallbackRender}>
      <NVThemeProvider useSnackbar usePreferredTheme brand="revolt">
        <AuthProvider
          approvedRoles={config.approvedRoles}
          redirectURL={config.redirectURL}
          cookieDomain={config.cookieDomain}
          cookieBaseName={config.cookieBaseName}
          cognitoUserPoolWebClientId={config.cognito.userPoolWebClientId}
          cognitoOauthUri={config.cognito.oauthUri}
          loadingComponent={<Loader full={true} />}
          onErrorComponent={
            <Box display="flex" justifyContent="center" alignItems="center" height={"100vh"}>
              {t("errors.authenticationError")}
            </Box>
          }
          onMissingRoleComponent={<MissingRole />}
        >
          <ApolloProvider config={config}>
            <NVLocalizationProviderDayjs adapterLocale={dayJSLocale}>
              <RouterProvider router={router} />
            </NVLocalizationProviderDayjs>
          </ApolloProvider>
        </AuthProvider>
      </NVThemeProvider>
    </ErrorBoundary>
  )
}

const FallbackRender = ({ error }: FallbackProps): JSX.Element => {
  Sentry.captureException(error)

  return (
    <div role="alert">
      <h1>Something went wrong</h1>
      <p>But we're working on fixing it!</p>
    </div>
  )
}
