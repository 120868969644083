import { OrderFragment, useAddContactMutation, useDeleteContactMutation } from "@gen/graphql"
import CloseIcon from "@mui/icons-material/Close"
import { Grid, IconButton } from "@mui/material"
import { Box } from "@mui/system"
import { Button, Stack, TextField, Typography } from "@northvolt/ui"
import { IconPlus } from "@shared"
import { JSX, useState } from "react"
import { useTranslation } from "react-i18next"

type ContactInputProps = {
  order: OrderFragment
  onHideInput: () => void
}

const ContactInput = ({ order, onHideInput }: ContactInputProps): JSX.Element => {
  const { t } = useTranslation()
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [addContact] = useAddContactMutation()

  const onSubmit = async (): Promise<void> => {
    try {
      await addContact({
        variables: {
          input: {
            contactName: name,
            phone: phone,
            pickupOrderId: order.id,
          },
        },
      }).then(onHideInput)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box sx={{ my: 2 }}>
      <Typography>{t("components.Contacts.title")}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("components.Contacts.labelName")}
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("components.Contacts.labelPhone")}
            variant="outlined"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, mr: 1 }}>
        <Button variant="text" color="secondary" onClick={onHideInput} sx={{ mr: 1 }}>
          {t("components.Contacts.cancel")}
        </Button>
        <Button color="secondary" onClick={onSubmit}>
          {t("components.Contacts.add")}
        </Button>
      </Box>
    </Box>
  )
}

type ContactProps = {
  order: OrderFragment
}
export const Contacts = ({ order }: ContactProps): JSX.Element => {
  const { t } = useTranslation()
  const [showInput, setShowInput] = useState(false)

  const [deleteContact] = useDeleteContactMutation()
  const handleRemove = async (contactID: string): Promise<void> => {
    try {
      await deleteContact({
        variables: {
          input: {
            pickupOrderId: order.id,
            contactId: contactID,
          },
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const toggleInput = (): void => {
    setShowInput((prev) => !prev)
  }

  return (
    <Box mt={3}>
      {order.contacts.length > 0 &&
        order.contacts.map((contact, index) => (
          <Box key={contact.id} sx={{ mt: 1, bgcolor: "background.default", p: 1 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>
                {t("components.Contacts.contactPerson")} {index + 1}: {contact.contactName} -{" "}
                {contact.phone}
              </Typography>
              <IconButton
                onClick={() => handleRemove(contact.id)}
                data-testid={`remove-contact-${index}`}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Box>
        ))}

      {showInput ? (
        <ContactInput order={order} onHideInput={toggleInput} />
      ) : (
        <Button
          variant="text"
          color="secondary"
          startIcon={<IconPlus />}
          onClick={toggleInput}
          sx={{ mt: 2 }}
          data-testid="add-pickup-contact-info"
        >
          {t("components.Contacts.addContact")}
        </Button>
      )}
    </Box>
  )
}
