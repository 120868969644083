import { EditBoxSmallVolumeStep } from "@components/PickupOrder/Steps/EditBoxSmallVolumeStep/EditBoxSmallVolumeStep"
import { EditBoxStep } from "@components/PickupOrder/Steps/EditBoxStep/EditBoxStep"
import {
  ExistingPickupOrderFlowQuery,
  PickupOrderFlow,
  TenantFragment,
  useExistingPickupOrderFlowQuery,
} from "@gen/graphql"
import { Loader, RouteError } from "@shared"
import { createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type BaseProps = {
  stepId: string
  groupId: string
  packageId: string
  order: ExistingPickupOrderFlowQuery
  tenant: TenantFragment
}

const Base = ({ order, groupId, packageId, tenant, stepId }: BaseProps): JSX.Element => {
  return stepToComponent(stepId, groupId, packageId, order, tenant?.pickupOrderFlow)
}

const stepToComponent = (
  stepId: string,
  groupId: string,
  boxId: string,
  order: ExistingPickupOrderFlowQuery,
  steps: PickupOrderFlow[],
): JSX.Element => {
  switch (steps[parseInt(stepId)]) {
    case PickupOrderFlow.AddPackageSmallVolume:
      return (
        <EditBoxSmallVolumeStep
          stepId={stepId}
          order={order.pickupOrder}
          groupId={groupId}
          selectedId={boxId}
        />
      )
    case PickupOrderFlow.AddPackage:
      return (
        <EditBoxStep
          stepId={stepId}
          order={order.pickupOrder}
          groupId={groupId}
          selectedId={boxId}
          packTypes={order.packTypes}
          boxTypes={order.boxTypes}
          moduleTypes={order.moduleTypes}
        />
      )

    default:
      return <div>Missing pickup order flow</div>
  }
}

const RouteComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { orderId, stepId, groupId, packageId } = Route.useParams()
  const { data, error, loading } = useExistingPickupOrderFlowQuery({
    variables: {
      id: orderId,
    },
  })

  if (loading) {
    return <Loader />
  }

  if (error || !data) {
    return <RouteError error={error || Error(t("errors.unknown"))} />
  }

  if (!data.tenant?.pickupOrderFlow) {
    return <RouteError error={Error("Missing pickup order flow")} />
  }

  const box = data?.pickupOrder.boxes.find((p) => p.id === packageId)
  if (!box) {
    return <RouteError error={Error("Package not found")} />
  }

  return (
    <Base
      stepId={stepId}
      groupId={groupId}
      packageId={packageId}
      order={data}
      tenant={data.tenant}
    />
  )
}

export const Route = createFileRoute(
  "/pickup-orders/$orderId/$stepId/groups/$groupId/packages_/$packageId/edit",
)({
  component: () => <RouteComponent />,
})
