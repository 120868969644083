import { IntroStep } from "@components/PickupOrder/Steps/IntroStep/IntroStep"
import { useProfilePageQuery } from "@gen/graphql"
import { Grid } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Box, Card, IconButton, Typography } from "@northvolt/ui"
import { IconArrowRight, IconClipBoard, IconTruck } from "@shared"
import { createFileRoute, useNavigate } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

const Index = (): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data } = useProfilePageQuery()
  const userName = data?.me.firstName || ""

  return (
    <>
      <Typography variant="headlineMedium" mb={4}>
        {t("routes.index.header", { name: userName })}
      </Typography>
      <Grid container spacing={4} mb={4}>
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 2 }}>
            <Circle color="primary">
              <IconTruck />
            </Circle>
            <Grid container spacing={4} mb={4}>
              <Grid item xs={10}>
                <Typography variant="headlineSmall" mt={2}>
                  {t("routes.index.pickups.title")}
                </Typography>
                <Typography variant="bodyMedium">{t("routes.index.pickups.body")}</Typography>
              </Grid>
              <Grid item xs={2} alignSelf={"center"} justifySelf={"flex-end"}>
                <Box sx={{ fontSize: "16px", display: "flex", justifyContent: "center" }}>
                  <IconButton
                    onClick={() => {
                      navigate({
                        to: "/pickup-orders",
                      })
                    }}
                  >
                    <IconArrowRight />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 2 }}>
            <Circle color="info">
              <IconClipBoard />
            </Circle>
            <Grid container spacing={4} mb={4}>
              <Grid item xs={10}>
                <Typography variant="headlineSmall" mt={2}>
                  {t("routes.index.followUp.title")}
                </Typography>
                <Typography variant="bodyMedium">{t("routes.index.followUp.body")}</Typography>
              </Grid>
              <Grid item xs={2} alignSelf={"center"} justifySelf={"flex-end"}>
                <Box
                  sx={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    color: "#000",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      navigate({
                        to: "/overview",
                      })
                    }}
                  >
                    <IconArrowRight />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <IntroStep />
    </>
  )
}

export const Route = createFileRoute("/")({
  component: Index,
})

const Circle = styled(Box)<{ color: "primary" | "info" }>`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  background: ${({ theme, color }) =>
    color === "primary" ? theme.palette.primary.main : theme.palette.info.main};
`
