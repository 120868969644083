import { useCreatePickupOrderMutation } from "@gen/graphql"
import { styled } from "@mui/material/styles"
import { Box, Button, Card, Grid, List, ListItem as NVListItem, Typography } from "@northvolt/ui"
import { useNavigate } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"
import introImg from "./intro.jpg"

export const IntroStep = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [create] = useCreatePickupOrderMutation()

  return (
    <Card>
      <Grid container spacing={0} data-testid="intro-step">
        <Grid sm={12} md={6}>
          <IntroImage />
        </Grid>
        <Grid sm={12} md={6}>
          <Box padding={6}>
            <Typography component="h1" fontSize={24}>
              {t("components.PickupOrderIntro.title")}
            </Typography>
            <List component="ol" sx={{ listStyle: "decimal", listStylePosition: "inside" }}>
              <ListItem>{t("components.PickupOrderIntro.step1")}</ListItem>
              <ListItem>{t("components.PickupOrderIntro.step2")}</ListItem>
              <ListItem>{t("components.PickupOrderIntro.step3")}</ListItem>
            </List>
            <Button
              data-testid="pickup-order-intro-action"
              onClick={() => {
                create().then(({ data }) => {
                  navigate({ to: `/pickup-orders/${data?.createPickupOrder?.id}/1` })
                })
              }}
            >
              {t("components.PickupOrderIntro.action")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

const IntroImage = (): JSX.Element => {
  return <MaxWithImage src={introImg} alt="pickup order intro" />
}

const MaxWithImage = styled("img")`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ListItem = styled(NVListItem)`
  display: list-item;
  padding-left: 0;
`
