import { CardActions as MUICardActions, Stack } from "@northvolt/ui"
import { JSX } from "react"

type CardActionProps = {
  children: JSX.Element | JSX.Element[]
}

export const CardActions = ({ children }: CardActionProps): JSX.Element => (
  <MUICardActions>
    <Stack direction="row" justifyContent="space-between" spacing={2} width="100%">
      {children}
    </Stack>
  </MUICardActions>
)
