import { Button } from "@northvolt/ui"
import { JSX } from "react"
import { useTranslation } from "react-i18next"
import { IconFileDownload } from "../../index"

type DownloadButtonProps = {
  getTrackingLabelFn: (orderID: string) => () => any
  orderID: string
  type: "simple" | "expanded"
}

export const DownloadButton = ({
  getTrackingLabelFn,
  orderID,
  type,
}: DownloadButtonProps): JSX.Element => {
  const { t } = useTranslation("components")
  const getTrackingLabel = getTrackingLabelFn(orderID)

  const downloadTrackingLabel = async (): Promise<void> => {
    const url = (await getTrackingLabel()).data?.trackingLabel.url
    if (url) {
      const link = document.createElement("a")
      link.target = "_blank"
      link.href = url
      link.download = "tracking-label.pdf"
      link.click()
    }
  }

  if (type === "simple") {
    return (
      <Button
        color="secondary"
        variant="text"
        onClick={() => {
          downloadTrackingLabel()
        }}
      >
        {t("TrackingLabel.download")}
        <IconFileDownload style={{ padding: 5 }} />
      </Button>
    )
  }

  return (
    <Button
      fullWidth
      color="secondary"
      variant="outlined"
      onClick={() => {
        downloadTrackingLabel()
      }}
    >
      <IconFileDownload style={{ padding: 5 }} />
      {t("TrackingLabel.download_expanded")}
    </Button>
  )
}
