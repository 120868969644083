import { LocationAndDateStep } from "@components/PickupOrder/Steps/LocationAndDateStep/LocationAndDateStep"
import {
  ExistingPickupOrderFlowQuery,
  PickupOrderFlow,
  useExistingPickupOrderFlowQuery,
} from "@gen/graphql"
import { Loader, RouteError } from "@shared"
import { createFileRoute } from "@tanstack/react-router"
import { TFunction } from "i18next"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type BaseProps = {
  order: ExistingPickupOrderFlowQuery
  pickupOrderFlow: PickupOrderFlow[]
  stepId: string
}

const Base = ({ order, pickupOrderFlow, stepId }: BaseProps): JSX.Element => {
  const { t } = useTranslation()

  return stepToComponent(t, stepId, order, pickupOrderFlow)
}

const stepToComponent = (
  t: TFunction<"common", undefined>,
  stepId: string,
  order: ExistingPickupOrderFlowQuery,
  steps: PickupOrderFlow[],
): JSX.Element => {
  switch (steps[parseInt(stepId)]) {
    case PickupOrderFlow.LocationAndDate:
      return <LocationAndDateStep order={order.pickupOrder} stepId={stepId} isEditMode={true} />
    default:
      return <div>{t("errors.missingFlow")}</div>
  }
}

const RouteComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { orderId, stepId } = Route.useParams()
  const { data, error, loading } = useExistingPickupOrderFlowQuery({
    variables: {
      id: orderId,
    },
  })

  if (loading) {
    return <Loader />
  }
  if (error || !data) {
    return <RouteError error={error || Error(t("errors.unknown"))} />
  }
  if (!data.tenant?.pickupOrderFlow) {
    return <RouteError error={Error(t("errors.flowNotRetrieved"))} />
  }
  return <Base order={data} pickupOrderFlow={data.tenant.pickupOrderFlow} stepId={stepId} />
}

export const Route = createFileRoute("/pickup-orders/$orderId/$stepId/edit")({
  component: RouteComponent,
})
