import { OrderStatus } from "@gen/graphql"
import { OrderFragment } from "@gen/graphql"
import { styled } from "@mui/material/styles"
import { Button, Card, CardContent, Typography } from "@northvolt/ui"
import { IconPlus } from "@shared"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type AddAnotherPackageProps = {
  order: OrderFragment
  setAdding: (state: boolean) => void
}

export const AddAnotherPackage = ({ order, setAdding }: AddAnotherPackageProps): JSX.Element => {
  const hasBoxes = order.boxes.length > 0
  const { t } = useTranslation()
  let buttonText = t("components.AddBox.title")
  if (hasBoxes) {
    buttonText = t("components.AddAnotherBox.title")
  }

  return (
    <Card>
      <CenteredCardContent>
        <Typography marginBlockEnd={2}>
          Check the package on the order summary by clicking on the package, or add another package
          here
        </Typography>
        <Button
          color="secondary"
          startIcon={<IconPlus />}
          variant="outlined"
          onClick={() => setAdding(true)}
          disabled={order?.status !== OrderStatus.Draft && order?.status !== OrderStatus.Pending}
        >
          {buttonText}
        </Button>
      </CenteredCardContent>
    </Card>
  )
}

const CenteredCardContent = styled(CardContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(6)};
`
