import { Box, Breakpoint, Drawer as MuiDrawer, Theme, useMediaQuery, useTheme } from "@mui/material"
import { SystemStyleObject } from "@mui/system"
import { JSX } from "react"

export const appBarOffset = "64px" // MUI Default AppBar height

/** Width of the drawer at each breakpoint, as per the figma design */
const drawerWidths: Record<Breakpoint, number | string> = {
  xl: 360,
  lg: 240,
  md: 240,
  sm: 240,
  xs: "100%",
}

type DrawerProps = {
  children?: JSX.Element
  open: boolean
}

export const Drawer = ({ open, children }: DrawerProps): JSX.Element => {
  const themeRoot = useTheme()
  const isDesktopView = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))

  const openedMixin = (theme: Theme): SystemStyleObject<Theme> => ({
    [theme.breakpoints.up("xs")]: {
      width: drawerWidths.xs,
    },
    [theme.breakpoints.up("sm")]: {
      width: drawerWidths.sm,
    },
    [theme.breakpoints.up("md")]: {
      width: drawerWidths.md,
    },
    [theme.breakpoints.up("lg")]: {
      width: drawerWidths.lg,
    },
    [theme.breakpoints.up("xl")]: {
      width: drawerWidths.xl,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    zIndex: 1000,
    boxShadow: "#00000025 0 4px 4px",
    mt: appBarOffset,
  })

  /** Mixin for the closed state of the drawer */
  const closedMixin = (theme: Theme): SystemStyleObject<Theme> => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    zIndex: 1000,
    boxShadow: "#00000025 0 4px 4px",
    width: `calc(${theme.spacing(10)} + 1px)`,
    mt: appBarOffset,
  })

  return (
    <MuiDrawer
      open={open}
      variant={isDesktopView ? "permanent" : "temporary"}
      sx={{
        padding: 2,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open
          ? {
              ...openedMixin(themeRoot),
              "& .MuiDrawer-paper": openedMixin(themeRoot),
            }
          : {
              ...closedMixin(themeRoot),
              "& .MuiDrawer-paper": closedMixin(themeRoot),
            }),
      }}
    >
      <Box sx={{ marginTop: 3, marginX: 1.5 }}>{children}</Box>
    </MuiDrawer>
  )
}
