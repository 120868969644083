import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@northvolt/ui"

type ConfirmationDialogProps = {
  open: boolean
  title: string
  message?: string
  onConfirm: () => void
  onCancel: () => void
  confirmButtonText: string
  cancelButtonText: string
  confirmButtonColor?:
    | "primary"
    | "error"
    | "secondary"
    | "inherit"
    | "success"
    | "info"
    | "warning"
  cancelButtonColor?: "primary" | "error" | "secondary" | "inherit" | "success" | "info" | "warning"
}

export const ConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor = "secondary",
  cancelButtonColor = "secondary",
}: ConfirmationDialogProps): React.JSX.Element => {
  return (
    <Dialog
      data-testid={"confirmation-dialog"}
      open={open}
      onClose={onCancel}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle style={{ minWidth: "444px" }} id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent style={{ justifyContent: "flex-start" }}>
        <DialogContentText id="dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end", padding: "16px 24px" }}>
        <Button onClick={onConfirm} color={confirmButtonColor} variant="text" autoFocus>
          {confirmButtonText}
        </Button>
        <Button onClick={onCancel} color={cancelButtonColor}>
          {cancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
