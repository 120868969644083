import { Card, Stack, Typography } from "@northvolt/ui"
import * as Sentry from "@sentry/react"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type RouteErrorProps = {
  error: Error
}

export const RouteError = ({ error }: RouteErrorProps): JSX.Element => {
  const { t } = useTranslation("components")
  Sentry.captureException(error)
  return (
    <Card>
      <Stack p={2} gap={2}>
        <Typography variant="headlineMedium">{t("RouteError.title")}</Typography>
        <Typography variant="bodyMedium">{t("RouteError.body")}</Typography>
        <Typography variant="bodySmall" color="error">
          {error.message}
        </Typography>
      </Stack>
    </Card>
  )
}
