import {
  BatteryStatus as BatteryStatusType,
  OrderFragment,
  useAddModuleMutation,
  useFileQuery,
  useUploadFileMutation,
} from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "@lib/i18n"
import CloseIcon from "@mui/icons-material/Close"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import { Card, InputAdornment } from "@mui/material"
import {
  Alert,
  Box,
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@northvolt/ui"
import { FormTextField } from "@northvolt/ui/Form"
import {
  BatteryStatus,
  CancelButton,
  CardActions,
  FileUpload,
  IconCarBattery,
  IconPlus,
  NumberInput,
  NumberSelect,
  RenderError,
  readableEnum,
} from "@shared"
import { JSX, useState } from "react"
import {
  Control,
  DefaultValues,
  FormProvider,
  SubmitHandler,
  UseFormSetValue,
  useFieldArray,
  useForm,
} from "react-hook-form"
import { useTranslation } from "react-i18next"

const moduleSchema = z
  .object({
    header: z.string(),
    moduleType: z.string(),
    applicationType: z.string().min(1),
    netWeight: z.number().min(1),
    moduleCopies: z.number().positive(),
    green: z.number().min(0),
    yellow: z.number().min(0),
    red: z.number().min(0),
    fileIDs: z.array(z.string()),
  })
  .refine((m) => m.green + m.yellow > 0, {
    message: "You must have at least one module of any status",
  })
type ZodModule = z.infer<typeof moduleSchema>
const boxSchema = z.object({
  modules: z.array(moduleSchema),
  boxType: z.string(),
  boxNetWeight: z.number(),
  boxCopies: z.coerce.number().positive(),
})
type ZodInputTypes = z.infer<typeof boxSchema>

const useGetFile = (fileID: string): string | undefined => {
  const { data } = useFileQuery({
    variables: {
      fileId: fileID,
    },
  })
  return data?.file?.url
}

type ModuleEntryeProps = {
  index: number
  module: ZodModule
  remove: (index: number) => void
  control: Control<ZodInputTypes>
  setValue: UseFormSetValue<ZodInputTypes>
  watch: (name: string) => ZodModule[]
}

const ModuleEntry = ({
  index,
  module,
  remove,
  control,
  setValue,
  watch,
}: ModuleEntryeProps): JSX.Element => {
  const { t } = useTranslation()
  const [unknownModuleType, setUnknownModuleType] = useState(false)

  const watchFileIDs = watch("modules")[index].fileIDs
  const hideUpload = watchFileIDs.length >= 4

  const useUploadFile = () => {
    const [upload] = useUploadFileMutation()
    return async (file: File): Promise<boolean> => {
      const { data, errors: errs } = await upload({
        variables: {
          file,
        },
      })
      if (!errs && data) {
        setValue(`modules.${index}.fileIDs`, [...watchFileIDs, data.upload.id])
      }
      return data !== undefined && errs === undefined
    }
  }
  const uploadFile = useUploadFile()
  const useRemoveFile = (id: string): void => {
    const updatedFileIds = watchFileIDs.filter((fileID: string) => fileID !== id)
    setValue(`modules.${index}.fileIDs`, updatedFileIds)
  }

  const [collapsed, setCollapsed] = useState(false)
  const handleToggleCollapse = (): void => {
    setCollapsed((prev) => !prev)
  }

  return (
    <Box
      padding={2}
      sx={{
        borderRadius: 1,
        bgcolor: "background.default",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitleMedium" alignContent="center">
            <IconCarBattery /> {readableEnum(module.header)}
          </Typography>
          <Box>
            <IconButton onClick={handleToggleCollapse}>
              {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
            <IconButton onClick={() => remove(index)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>
        {!collapsed && (
          <>
            <Box>
              <FormTextField
                control={control}
                name={`modules.${index}.moduleType`}
                label={t("components.AddModuleSmallVolume.moduleTypeLabel")}
                disabled={unknownModuleType}
                placeholder="Write here"
                fullWidth
              />
              <Checkbox
                label={t("components.AddModuleSmallVolume.moduleTypeCheckboxLabel")}
                onChange={(evt) => {
                  setUnknownModuleType(evt.currentTarget.checked)
                  setValue(
                    `modules.${index}.moduleType`,
                    evt.currentTarget.checked
                      ? t("components.AddModuleSmallVolume.unknownType")
                      : "",
                  )
                }}
                sx={{ ml: 2 }}
              />
            </Box>
            <FormTextField
              control={control}
              name={`modules.${index}.applicationType`}
              label={t("components.AddModuleSmallVolume.applicationTypeLabel")}
              placeholder="Write here"
            />
            <NumberInput
              label={t("components.AddModuleSmallVolume.moduleNetWeightLabel")}
              fullWidth
              control={control}
              name={`modules.${index}.netWeight`}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
            />
            {/* Battery Status */}
            <Stack direction="row" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  marginRight: { sm: 2 },
                  marginBottom: { xs: 2 },
                }}
              >
                <BatteryStatus
                  status={BatteryStatusType.Green}
                  label={t("components.basics.batteryStatus.green")}
                />
                <NumberSelect
                  control={control}
                  setValue={setValue}
                  name={`modules.${index}.green`}
                  minNumber={0}
                  sx={{
                    "& input": {
                      width: "100%",
                    },
                  }}
                />
                <RenderError control={control} name={`modules.${index}.green`} />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  marginLeft: { sm: 2 },
                  marginBottom: { xs: 2 },
                }}
              >
                <BatteryStatus
                  status={BatteryStatusType.Yellow}
                  label={t("components.basics.batteryStatus.yellow")}
                />
                <NumberSelect
                  control={control}
                  setValue={setValue}
                  name={`modules.${index}.yellow`}
                  minNumber={0}
                  sx={{
                    "& input": {
                      width: "100%",
                    },
                  }}
                />
                <RenderError control={control} name={`modules.${index}.yellow`} />
              </Box>
            </Stack>
            {/* FileUpload */}
            <Box>
              <Typography variant="bodyMedium">
                {t("components.FileUpload.module_title")}
              </Typography>
              <Typography variant="captionSmall" component={"p"} color="secondary">
                {t("components.FileUpload.body")}
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" gap={2}>
              {watchFileIDs.map((fileID) => (
                <FileUpload
                  key={fileID}
                  fileID={fileID}
                  get={useGetFile}
                  upload={uploadFile}
                  remove={useRemoveFile}
                  allowUpload={true}
                  allowRemove={true}
                />
              ))}
            </Box>
            {!hideUpload && (
              <FileUpload
                get={useGetFile}
                upload={uploadFile}
                remove={useRemoveFile}
                allowUpload={true}
                allowRemove={true}
              />
            )}
            <RenderError control={control} name={`modules.${index}`} />
          </>
        )}
      </Stack>
    </Box>
  )
}

type AddModuleSmallVolumeProps = {
  order: OrderFragment
  setType: (state: undefined) => void
}

export const AddModuleSmallVolume = ({
  order,
  setType,
}: AddModuleSmallVolumeProps): JSX.Element => {
  const { t } = useTranslation()
  const [addModuleMutation] = useAddModuleMutation()
  const [moduleCount, setModuleCount] = useState(2)
  const [unknownBoxType, setUnknownBoxType] = useState(false)
  const defaultModule = {
    moduleType: "",
    header: t("components.AddModuleSmallVolume.module") + " 1",
    green: 0,
    yellow: 0,
    red: 0,
    netWeight: 0,
    applicationType: "",
    moduleCopies: 1,
    fileIDs: [],
  }
  const defaultValues: Required<DefaultValues<ZodInputTypes>> = {
    modules: [
      {
        ...defaultModule,
      },
    ],
    boxCopies: 1,
    boxNetWeight: 0,
    boxType: "",
  }
  const methods = useForm<ZodInputTypes>({
    resolver: zodResolver(boxSchema),
    mode: "onBlur",
    defaultValues,
  })
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid },
  } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: "modules",
  })
  const watchModules = watch("modules")
  const watchBoxNetWeight = watch("boxNetWeight")

  const grossWeight =
    watchModules.reduce((acc, m) => {
      return acc + m.netWeight * (m.green + m.yellow)
    }, 0) + watchBoxNetWeight

  const onSubmit: SubmitHandler<ZodInputTypes> = (data: ZodInputTypes) => {
    addModuleMutation({
      variables: {
        input: {
          pickupOrderId: order.id,
          etag: order.etag,
          boxType: {
            displayName: data.boxType,
            netWeight: data.boxNetWeight,
          },
          count: data.boxCopies,
          modules: data.modules.map((m) => ({
            moduleType: {
              displayName: m.moduleType,
            },
            netWeight: m.netWeight,
            green: m.green,
            yellow: m.yellow,
            red: 0,
            fileIDs: m.fileIDs,
          })),
        },
      },
    }).then(() => {
      setType(undefined)
    })
  }

  // Add a new module when the button is clicked
  const addNewModule = (): void => {
    append({
      ...defaultModule,
      header: `Module ${moduleCount}`,
    })
    setModuleCount(moduleCount + 1)
  }

  return (
    <Card>
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title={t("components.AddModuleSmallVolume.header")} />
          <CardContent>
            <Stack spacing={2}>
              <Alert severity="info">
                {t("components.AddModuleSmallVolume.moduleLimitDescription")}
              </Alert>
              {fields.map((module, index) => (
                <ModuleEntry
                  key={module.id}
                  index={index}
                  module={module}
                  remove={remove}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                />
              ))}

              {/* Button to add new module */}
              <Button
                onClick={addNewModule}
                color="primary"
                startIcon={<IconPlus />}
                sx={{
                  alignSelf: "flex-start",
                  mb: 3,
                  background: "none",
                  boxShadow: "none",
                  color: "inherit",
                  "&:hover": {
                    background: "none",
                  },
                }}
              >
                {t("components.AddModuleSmallVolume.addButton")}
              </Button>

              {watchModules.length > 0 && (
                <>
                  <Box>
                    <Typography variant="subtitleMedium" color="secondary" mb={2}>
                      {t("components.AddModuleSmallVolume.boxType.header")}
                    </Typography>
                    <FormTextField
                      control={control}
                      label={t("components.AddModuleSmallVolume.boxType.label")}
                      name="boxType"
                      fullWidth
                      placeholder="Write here"
                      disabled={unknownBoxType}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Inventory2OutlinedIcon></Inventory2OutlinedIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Checkbox
                      label={t("components.AddModuleSmallVolume.boxType.checkboxLabel")}
                      onChange={(evt) => {
                        setUnknownBoxType(evt.currentTarget.checked)
                        setValue(
                          "boxType",
                          evt.currentTarget.checked
                            ? t("components.AddModuleSmallVolume.unknownType")
                            : "",
                        )
                      }}
                      sx={{ ml: 2 }}
                    />
                  </Box>
                  <NumberInput
                    label={t("components.AddModuleSmallVolume.boxType.netWeightLabel")}
                    fullWidth
                    control={control}
                    name="boxNetWeight"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                  />
                  <TextField
                    disabled
                    label={t("components.basics.grossWeight")}
                    fullWidth
                    value={grossWeight}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                  />
                  <TextField
                    label={t("components.AddModuleSmallVolume.descriptionLabel")}
                    fullWidth
                    multiline
                  />
                  <Box sx={{ background: "#F3F3F3", borderRadius: "4px", padding: "16px" }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography fontSize={16} component="h2" sx={{ margin: 2 }}>
                        {t("components.AddModule.copies.title")}
                      </Typography>
                      <NumberSelect
                        control={control}
                        setValue={setValue}
                        name="boxCopies"
                        minNumber={1}
                      />
                    </Stack>
                    <RenderError control={control} name="boxCopies" />
                  </Box>
                </>
              )}
            </Stack>
          </CardContent>

          <CardActions>
            <CancelButton onClick={() => setType(undefined)} />
            <Button type="submit" color="secondary" disabled={!isValid} startIcon={<IconPlus />}>
              {t("components.AddBox.submit")}
            </Button>
          </CardActions>
        </form>
      </FormProvider>
    </Card>
  )
}
