import { BatteryStatus as BatteryStatusType } from "@gen/graphql"
import { InputAdornment, Typography } from "@mui/material"
import { Box, Stack } from "@northvolt/ui"
import { BatteryStatus, IconCarBattery, NumberInput, NumberSelect, RenderError } from "@shared"
import { readableEnum } from "@shared"
import { JSX } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ZodInputTypes, ZodModule } from "../AddBox/AddModule"

export const Module = ({ index, module }: { index: number; module: ZodModule }): JSX.Element => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<ZodInputTypes>()

  return (
    <Box
      padding={2}
      sx={{
        borderRadius: 1,
        bgcolor: "background.default",
      }}
    >
      <Typography variant="subtitleMedium" marginBottom={2}>
        <IconCarBattery /> {readableEnum(module.displayName)}
      </Typography>
      <NumberInput
        label={t("components.basics.netWeight")}
        fullWidth
        control={control}
        name={`modules.${index}.netWeight`}
        InputProps={{
          endAdornment: <InputAdornment position="end">kg</InputAdornment>,
        }}
      />
      <Stack direction="row" marginTop={4} spacing={2} sx={{ flexWrap: "wrap" }}>
        <Box>
          <BatteryStatus
            status={BatteryStatusType.Green}
            label={t("components.basics.batteryStatus.green")}
          />

          <NumberSelect
            control={control}
            setValue={setValue}
            name={`modules.${index}.green`}
            minNumber={0}
          />
          <RenderError control={control} name={`modules.${index}.green`} />
        </Box>

        <Box>
          <BatteryStatus
            status={BatteryStatusType.Yellow}
            label={t("components.basics.batteryStatus.yellow")}
          />

          <NumberSelect
            control={control}
            setValue={setValue}
            name={`modules.${index}.yellow`}
            minNumber={0}
          />
          <RenderError control={control} name={`modules.${index}.yellow`} />
        </Box>
        <Box>
          <BatteryStatus
            status={BatteryStatusType.Red}
            label={t("components.basics.batteryStatus.red")}
          />

          <NumberSelect
            control={control}
            setValue={setValue}
            name={`modules.${index}.red`}
            minNumber={0}
          />
          <RenderError control={control} name={`modules.${index}.red`} />
        </Box>
      </Stack>
      <RenderError control={control} name={`modules.${index}`} />
    </Box>
  )
}
