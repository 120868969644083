import { Outlet, createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"

const Pickup = (): JSX.Element => {
  return <Outlet />
}

export const Route = createFileRoute("/pickup-orders")({
  component: () => <Pickup />,
})
