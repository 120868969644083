import { AddAnotherPackage } from "@components/PickupOrder/Components/AddAnotherPackage/AddAnotherPackage"
import { AddBox } from "@components/PickupOrder/Components/AddBox/AddBox"
import { OrderSummary } from "@components/PickupOrder/Components/OrderSummary/OrderSummary"
import { ExistingPickupOrderFlowQuery, useSubmitOrderMutation } from "@gen/graphql"
import { Grid } from "@northvolt/ui"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useState } from "react"

type AddBoxStepProps = {
  order: ExistingPickupOrderFlowQuery
  stepId: string
}

export const AddBoxStep = ({ order, stepId }: AddBoxStepProps): JSX.Element => {
  const [submitOrder] = useSubmitOrderMutation()
  const navigate = useNavigate()
  const [adding, setAdding] = useState(false)

  const submit = (): void => {
    submitOrder({
      variables: {
        input: {
          pickupOrderId: order.pickupOrder.id,
          etag: order.pickupOrder.etag,
        },
      },
    }).then((result) => {
      navigate({
        to: "/pickup-orders/$orderId/$stepId",
        search: {
          submittedOrderId: result.data?.submitPickupOrder.id,
        },
        params: {
          orderId: order.pickupOrder.id,
          stepId: stepId,
        },
      })
    })
  }
  return (
    <Grid container spacing={2} data-testid="add-box-step">
      <Grid xs={12} md={6}>
        {!adding && <AddAnotherPackage order={order.pickupOrder} setAdding={setAdding} />}
        {adding && (
          <AddBox
            order={order.pickupOrder}
            boxTypes={order.boxTypes}
            moduleTypes={order.moduleTypes}
            packTypes={order.packTypes}
            setAdding={setAdding}
          />
        )}
      </Grid>
      <Grid xs={12} md={6}>
        <OrderSummary order={order.pickupOrder} submit={submit} stepId={stepId} />
      </Grid>
    </Grid>
  )
}
