type Order = {
  boxes: Box[]
}

type Box = {
  boxType: BoxType
  items: Item[]
}

type BoxType = {
  netWeight: number
}

type Item = Pack | Module

type Pack = {
  netWeight: number
  __typename?: "Pack"
}

type Module = {
  __typename?: "Module"
  netWeight: number
  green: number
  yellow: number
  red: number
}

export const sumOrderGrossWeight = (order: Order): number =>
  sumOrderNetWeight(order) + order.boxes.reduce((acc, box) => acc + box.boxType.netWeight, 0)

export const sumOrderNetWeight = (order: Order): number =>
  order.boxes.reduce(
    (acc, box) =>
      acc +
      box.items.reduce((a, i) => {
        if (i.__typename === "Pack") {
          return a + i.netWeight
        }
        if (i.__typename === "Module") {
          return a + i.netWeight * (i.green + i.yellow + i.red)
        }
        return a
      }, 0),
    0,
  )
