import { Button } from "@mui/material"
import { styled } from "@mui/material/styles"

export const CollapsableIconButton = styled(Button)<{ open: boolean }>(({ open }) => ({
  minWidth: 0,
  "& span:not(.MuiButton-icon)": {
    display: open ? "block" : "none",
    marginLeft: open ? "16px" : 0,
  },
  "& .MuiButton-icon": {
    margin: 0,
    padding: 0,
  },
}))
