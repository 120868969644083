import { IntroSmallVolumeStep } from "@components/PickupOrder/Steps/IntroSmallVolumeStep/IntroSmallVolumeStep"
import { IntroStep } from "@components/PickupOrder/Steps/IntroStep/IntroStep"
import { PickupOrderFlow, TenantFragment, useNewPickupOrderFlowQuery } from "@gen/graphql"
import { Loader, RouteError } from "@shared"
import { createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

/*
 * This is the main entry point for the pickup order flow.
 * It will determine which step to render based on the tenant configuration.
 * The first step is determined by the first element in the pickupOrderFlow array.
 * Once we move past the first flow we must have a created order to continue.
 */
const PickupIndexComponent = ({ tenant }: { tenant: TenantFragment }): JSX.Element => {
  return <>{firstStep(tenant)}</>
}

const RouteComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { data, error, loading } = useNewPickupOrderFlowQuery()

  if (loading) {
    return <Loader />
  }

  if (error || !data || !data.tenant) {
    return <RouteError error={error || Error(t("errors.unknown"))} />
  }

  return <PickupIndexComponent tenant={data.tenant} />
}

export const Route = createFileRoute("/pickup-orders/")({
  component: RouteComponent,
})

const firstStep = (tenant: TenantFragment): JSX.Element => {
  switch (tenant.pickupOrderFlow[0]) {
    case PickupOrderFlow.Intro:
      return <IntroStep />
    case PickupOrderFlow.IntroSmallVolume:
      return <IntroSmallVolumeStep />
    default:
      return <div>Missing pickup order flow</div>
  }
}
