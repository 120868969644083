import { OverviewDrawer } from "@components/Overview/OverviewDrawer"
import { OrderListFragment, usePickupOrdersQuery } from "@gen/graphql"
import { Loader, RouteError } from "@shared"
import { createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type PickupOrderListComponentProps = {
  orders: OrderListFragment[] | undefined
  selectedOrderId?: string
}

const PickupOrderListComponent = ({
  orders,
  selectedOrderId,
}: PickupOrderListComponentProps): JSX.Element => {
  return <OverviewDrawer orders={orders ?? []} selectedOrderId={selectedOrderId} />
}

const RouteComponent = (): JSX.Element => {
  const { orderId } = Route.useParams()
  const { data, error, loading } = usePickupOrdersQuery()
  const { t } = useTranslation()

  if (loading) {
    return <Loader />
  }

  if (error || !data) {
    return <RouteError error={error || Error(t("errors.unknown"))} />
  }

  return <PickupOrderListComponent selectedOrderId={orderId} orders={data.pickupOrders} />
}

export const Route = createFileRoute("/overview/$orderId")({
  component: () => <RouteComponent />,
})
