import { Chip } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Theme } from "@mui/system"

type OrderStatus = "Approved" | "Arrived" | "AwaitingApproval" | "Draft" | "Pending" | "Unknown"

export const StatusChip = styled(Chip)<{ status: OrderStatus }>`
  background: ${({ theme, status }) => statusToColors(theme, status).background};
  color: ${({ theme, status }) => statusToColors(theme, status).color};
`

const statusToColors = (
  theme: Theme,
  status: OrderStatus,
): { background: string; color: string } => {
  switch (status) {
    case "Draft":
      return {
        color: "#000",
        background: "#EDEDED",
      }
    case "Pending":
      return {
        color: "#000",
        background: "#FFE18F",
      }
    case "Approved":
      return {
        color: "#000",
        background: "#B4E4CE",
      }
    case "Arrived":
      return {
        color: "#000",
        background: theme.palette.info.light,
      }
    default:
      return {
        color: "#000",
        background: theme.palette.text.light,
      }
  }
}
