import { OrderListFragment } from "@gen/graphql"
import { JSX } from "react"
import { PickupOrderList } from "./PickupOrderList"

type OverviewProps = {
  orders: OrderListFragment[]
}

export const Overview = ({ orders }: OverviewProps): JSX.Element => {
  return <PickupOrderList orders={orders} />
}
