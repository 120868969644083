/* eslint-disable react/jsx-props-no-spreading */
import { TextField, TextFieldProps } from "@northvolt/ui"
import { JSX } from "react"
import { Control, FieldPath, FieldValues, useController } from "react-hook-form"

export const NumberInput = <T extends FieldValues, U extends FieldPath<T>>({
  control,
  name,
  ...props
}: {
  control: Control<T>
  name: U
} & TextFieldProps): JSX.Element => {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ control, name })

  return (
    <TextField
      label="Number"
      type="number"
      {...props}
      {...field}
      onChange={(e) => {
        onChange(parseFloat(e.target.value))
      }}
      error={Boolean(error)}
      helperText={error?.message}
    />
  )
}
