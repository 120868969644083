import { EditPackSmallVolume } from "@components/PickupOrder/Components/EditPackSmallVolume/EditPackSmallVolume"
import { GroupSummaryList } from "@components/PickupOrder/Components/GroupSummaryList/GroupSummaryList"
import { OrderFragment } from "@gen/graphql"
import { Card, Grid } from "@northvolt/ui"
import { JSX } from "react"

type EditBoxSmallVolumeStepProps = {
  order: OrderFragment
  stepId: string
  groupId: string
  selectedId: string
}

export const EditBoxSmallVolumeStep = ({
  order,
  stepId,
  groupId,
  selectedId,
}: EditBoxSmallVolumeStepProps): JSX.Element => {
  const box = order.boxes.find((b) => b.id === selectedId)
  if (!box) {
    return <Card>Box not found</Card>
  }
  const isPack = box.items[0].__typename === "Pack"
  const isModule = box.items[0].__typename === "Module"

  return (
    <Grid container spacing={2} data-testid="edit-box-small-volume-step">
      <Grid sm={12} md={6}>
        {isPack && <EditPackSmallVolume stepId={stepId} order={order} box={box} />}
        {isModule && <Card>Not yet implemented</Card>}
      </Grid>
      <Grid sm={12} md={6}>
        <GroupSummaryList stepId={stepId} order={order} groupId={groupId} selectedId={selectedId} />
      </Grid>
    </Grid>
  )
}
