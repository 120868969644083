import { config } from "@lib/config"
import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"

Sentry.init({
  dsn: "https://177cd4bc8485c8527f473b2e065a3187@o292401.ingest.us.sentry.io/4506619900395520",
  integrations: [Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/northvolt\.com/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Disable sentry in development mode
  enabled: config.mode !== "development",
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
