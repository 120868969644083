import { Box, Typography } from "@northvolt/ui"
import { JSX } from "react"
import { Control, FieldPath, FieldValues, useController } from "react-hook-form"

export const RenderError = <T extends FieldValues>({
  control,
  name,
}: {
  control: Control<T>
  name: FieldPath<T>
}): JSX.Element => {
  const {
    fieldState: { error },
  } = useController({ control, name })

  return (
    <Box>
      <Typography color="error">{error?.message}</Typography>
      <Typography color="error">{error?.root?.message}</Typography>
    </Box>
  )
}
