import { BatteryStatus as BatteryStatusType } from "@gen/graphql"
import CloseIcon from "@mui/icons-material/Close"
import { InputAdornment, Typography } from "@mui/material"
import { Box, IconButton, Stack } from "@northvolt/ui"
import { BatteryStatus, IconCarBattery, NumberInput, NumberSelect, RenderError } from "@shared"
import { readableEnum } from "@shared"
import { JSX } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ZodInputTypes, ZodPack } from "../AddBox/AddPack"

type PackProps = {
  index: number
  pack: ZodPack
  remove: (index: number) => void
}

export const Pack = ({ index, pack, remove }: PackProps): JSX.Element => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<ZodInputTypes>()

  return (
    <Box
      padding={2}
      sx={{
        borderRadius: 1,
        bgcolor: "background.default",
      }}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="subtitleMedium" alignContent="center">
          <IconCarBattery /> {readableEnum(pack.displayName)}
        </Typography>
        <IconButton onClick={() => remove(index)}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <NumberInput
        label={t("components.basics.netWeight")}
        fullWidth
        control={control}
        name={`packs.${index}.netWeight`}
        data-testid="net-weight-input"
        InputProps={{
          endAdornment: <InputAdornment position="end">kg</InputAdornment>,
        }}
      />

      <Stack direction="row" marginTop={4} spacing={2} sx={{ flexWrap: "wrap" }}>
        <Box>
          <BatteryStatus
            status={BatteryStatusType.Green}
            label={t("components.basics.batteryStatus.green")}
          />
          <NumberSelect
            control={control}
            setValue={setValue}
            name={`packs.${index}.green`}
            minNumber={0}
          />
          <RenderError control={control} name={`packs.${index}.green`} />
        </Box>

        <Box>
          <BatteryStatus
            status={BatteryStatusType.Yellow}
            label={t("components.basics.batteryStatus.yellow")}
          />
          <NumberSelect
            control={control}
            setValue={setValue}
            name={`packs.${index}.yellow`}
            minNumber={0}
          />
          <RenderError control={control} name={`packs.${index}.yellow`} />
        </Box>
        <Box>
          <BatteryStatus
            status={BatteryStatusType.Red}
            label={t("components.basics.batteryStatus.red")}
          />

          <NumberSelect
            control={control}
            setValue={setValue}
            name={`packs.${index}.red`}
            minNumber={0}
          />
          <RenderError control={control} name={`packs.${index}.red`} />
        </Box>
      </Stack>
    </Box>
  )
}
