import {
  OrderFragment,
  useAddLocationAndDateMutation,
  usePickupOrderLocationsQuery,
} from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { InputAdornment } from "@mui/material"
import { Alert, Card, CardContent, Stack, Typography, useSnackbar } from "@northvolt/ui"
import {
  AutoCompleteInput,
  CancelButton,
  CardActions,
  Date,
  IconLocationDot,
  NextButton,
  disableDates,
  getEarliestPickupDate,
} from "@shared"
import { useNavigate } from "@tanstack/react-router"
import dayjs, { type Dayjs } from "dayjs"
import { JSX } from "react"
import { DefaultValues, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { Contacts } from "../Contacts/Contacts"

const earliestPickupDate = getEarliestPickupDate()

const formSchema = z.object({
  location: z.string().uuid(),
  date: z
    .instanceof(dayjs as unknown as typeof Dayjs)
    .refine((date) => !disableDates(earliestPickupDate)(date)),
})

type InputTypes = z.infer<typeof formSchema>

type LocationAndDateProps = {
  order: OrderFragment
  stepId: string
}

export const LocationAndDate = ({ order, stepId }: LocationAndDateProps): JSX.Element => {
  const { data, error } = usePickupOrderLocationsQuery({ variables: {} })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateSnackbar = useSnackbar()

  const defaultValues: Required<DefaultValues<InputTypes>> = {
    location: order.pickupLocation?.id || "",
    date: order.pickupDate ? dayjs(order.pickupDate) : earliestPickupDate,
  }

  const [addLocationAndDate] = useAddLocationAndDateMutation()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<InputTypes>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const handleClick = (): void => {
    if (!isValid) {
      updateSnackbar({
        message: t("components.LocationAndDate.dateTooEarly"),
        severity: "error",
      })
    }
  }

  const onSubmit: SubmitHandler<InputTypes> = (submitData) => {
    addLocationAndDate({
      variables: {
        input: {
          pickupOrderId: order.id,
          etag: order.etag,
          locationId: submitData.location,
          date: submitData.date.format("YYYY-MM-DD"),
        },
      },
    }).then((res) => {
      if (!res.data?.addLocationAndDate?.id) {
        updateSnackbar({
          message: t("components.LocationAndDate.addFail"),
          severity: "error",
        })
      } else {
        updateSnackbar({
          message: t("components.LocationAndDate.addSuccess"),
          severity: "success",
        })
        navigate({
          to: "/pickup-orders/$orderId/$stepId",
          params: { orderId: res.data.addLocationAndDate.id, stepId: `${parseInt(stepId) + 1}` },
        })
      }
    })
  }

  if (error) throw new Error("Error loading pickup order locations")

  if (!data) return <div>{t("components.basics.loading")}</div>

  const locations =
    data.locations.map<{ label: string; value: string }>((location) => ({
      label: location.displayName,
      value: location.id,
    })) ?? []

  return (
    <Card>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Stack spacing={1}>
            <Typography fontSize={16} component="h2" sx={{ mb: 2 }}>
              {t("components.LocationAndDate.title")}
            </Typography>

            {locations && (
              <AutoCompleteInput
                endAdornment={
                  <InputAdornment position="end">
                    <IconLocationDot />
                  </InputAdornment>
                }
                data-testid="pickup-order-location"
                name="location"
                label={t("components.LocationAndDate.selectLocation.label")}
                control={control}
                options={locations}
              />
            )}

            <Date control={control} name="date" earliestPickupDate={earliestPickupDate} />

            {errors.location && (
              <Alert sx={{ bgcolor: "inherit", alignSelf: "center" }} severity="error">
                {errors.location.message}
              </Alert>
            )}
            {errors.date && (
              <Alert sx={{ bgcolor: "inherit", alignSelf: "center" }} severity="error">
                {errors.date.message}
              </Alert>
            )}
          </Stack>

          <Contacts order={order} />
        </CardContent>
        <CardActions>
          <CancelButton onClick={() => navigate({ to: "/pickup-orders" })} />
          <div onClick={handleClick}>
            <NextButton isValid={isValid} />
          </div>
        </CardActions>
      </form>
    </Card>
  )
}
