import { SubmittedModal } from "@components/PickupOrder/Components/SubmittedModal/SubmittedModal"
import { AddBoxSmallVolumeStep } from "@components/PickupOrder/Steps/AddBoxSmallVolumeStep/AddBoxSmallVolumeStep"
import { AddBoxStep } from "@components/PickupOrder/Steps/AddBoxStep/AddBoxStep"
import { IntroStep } from "@components/PickupOrder/Steps/IntroStep/IntroStep"
import { LocationAndDateStep } from "@components/PickupOrder/Steps/LocationAndDateStep/LocationAndDateStep"
import {
  ExistingPickupOrderFlowQuery,
  PickupOrderFlow,
  useExistingPickupOrderFlowQuery,
} from "@gen/graphql"
import { Loader, RouteError } from "@shared"
import { createFileRoute } from "@tanstack/react-router"
import { TFunction } from "i18next"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type PickupOrderBaseProps = {
  order: ExistingPickupOrderFlowQuery
  submittedOrderId: string
  stepId: string
}

const PickupOrderBase = ({
  order,
  submittedOrderId,
  stepId,
}: PickupOrderBaseProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      {submittedOrderId && <SubmittedModal order={order.pickupOrder} />}
      {order.tenant?.pickupOrderFlow &&
        stepToComponent(t, order, stepId, order.tenant?.pickupOrderFlow)}
    </>
  )
}

const stepToComponent = (
  t: TFunction<"common", undefined>,
  order: ExistingPickupOrderFlowQuery,
  stepId: string,
  steps: PickupOrderFlow[],
): JSX.Element => {
  switch (steps[parseInt(stepId)]) {
    case PickupOrderFlow.Intro:
      return <IntroStep />
    case PickupOrderFlow.LocationAndDate:
      return <LocationAndDateStep order={order.pickupOrder} stepId={stepId} isEditMode={false} />
    case PickupOrderFlow.AddPackage:
      return <AddBoxStep order={order} stepId={stepId} />
    case PickupOrderFlow.AddPackageSmallVolume:
      return <AddBoxSmallVolumeStep order={order.pickupOrder} stepId={stepId} />
    default:
      return <div>{t("errors.missingFlow")}</div>
  }
}

const RouteComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { submittedOrderId } = Route.useSearch<{ submittedOrderId: string }>()
  const { orderId, stepId } = Route.useParams()
  const { data, error, loading } = useExistingPickupOrderFlowQuery({
    variables: {
      id: orderId,
    },
  })

  if (loading) {
    return <Loader />
  }

  if (error || !data) {
    return <RouteError error={error || Error(t("errors.unknown"))} />
  }

  return <PickupOrderBase order={data} submittedOrderId={submittedOrderId} stepId={stepId} />
}

export const Route = createFileRoute("/pickup-orders/$orderId/$stepId/")({
  component: RouteComponent,
})
