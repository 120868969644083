import { SubmittedModal } from "@components/PickupOrder/Components/SubmittedModal/SubmittedModal"
import { OrderFragment, OrderStatus, useExistingPickupOrderFlowQuery } from "@gen/graphql"
import { Typography } from "@mui/material"
import { Loader, RouteError } from "@shared"
import { Outlet, createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type PickupOrderBaseProps = {
  order: OrderFragment
  submittedOrderId: string
}

const PickupOrderBase = ({ order, submittedOrderId }: PickupOrderBaseProps): JSX.Element => {
  const { t } = useTranslation()

  const title =
    order.status === OrderStatus.Draft ? t("routes.pickupOrder") : t("routes.updatePickupOrder")

  return (
    <>
      <Typography variant="headlineMedium" sx={{ mb: 3 }}>
        {title}
      </Typography>
      {submittedOrderId && <SubmittedModal order={order} />}
      <Outlet />
    </>
  )
}

const RouteComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const { submittedOrderId } = Route.useSearch<{ submittedOrderId: string }>()
  const { orderId } = Route.useParams()
  const { data, error, loading } = useExistingPickupOrderFlowQuery({
    variables: {
      id: orderId,
    },
  })

  if (loading) {
    return <Loader />
  }

  if (error || !data) {
    return <RouteError error={error || Error(t("errors.unknown"))} />
  }

  return <PickupOrderBase order={data.pickupOrder} submittedOrderId={submittedOrderId} />
}

export const Route = createFileRoute("/pickup-orders/$orderId/$stepId")({
  component: RouteComponent,
})
