import dayjs from "dayjs"

const disableWeekends = (date: dayjs.Dayjs): boolean => {
  return date.day() === 0 || date.day() === 6
}

export const getEarliestPickupDate = (NumberOfDisabledDays = 4): dayjs.Dayjs => {
  let earliestPickupDate = dayjs().add(NumberOfDisabledDays, "day")

  while (disableWeekends(earliestPickupDate)) {
    earliestPickupDate = earliestPickupDate.add(1, "day")
  }
  return earliestPickupDate
}

export const disableDates =
  (earliestPickupDate: dayjs.Dayjs) =>
  (date: dayjs.Dayjs): boolean => {
    return date.isBefore(earliestPickupDate, "day") || disableWeekends(date)
  }
