/* eslint-disable no-param-reassign */
import {
  BoxTypeFragment,
  ModuleTypeFragment,
  OrderFragment,
  useAddModuleMutation,
} from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "@lib/i18n"
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material"
import {
  Alert,
  Box,
  Button,
  CardContent,
  Checkbox,
  Chip,
  Grid,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@northvolt/ui"
import { CancelButton, CardActions, IconBox, IconPlus, NumberSelect, RenderError } from "@shared"
import { readableEnum } from "@shared"
import { JSX } from "react"
import { DefaultValues, FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Module } from "../Module/Module"

export const moduleSchema = z
  .object({
    moduleId: z.string(),
    moduleTypeId: z.string(),
    displayName: z.string(),
    green: z.coerce.number().min(0),
    yellow: z.coerce.number().min(0),
    red: z.coerce.number().min(0),
    netWeight: z.coerce.number().min(0),
  })
  .refine((m) => m.green + m.yellow + m.red > 0, {
    message: "You must have at least one module of any status",
  })
export type ZodModule = z.infer<typeof moduleSchema>
const boxSchema = z.object({
  modules: z.array(moduleSchema).min(1),
  copies: z.coerce.number().positive(),
})
export type ZodInputTypes = z.infer<typeof boxSchema>

type AddModuleProps = {
  order: OrderFragment
  moduleTypes: ModuleTypeFragment[]
  selectedBoxType: BoxTypeFragment
  setAdding: (state: boolean) => void
}

export const AddModule = ({
  order,
  moduleTypes,
  selectedBoxType,
  setAdding,
}: AddModuleProps): JSX.Element => {
  const { t } = useTranslation()
  const [addModule] = useAddModuleMutation()
  const defaultValues: Required<DefaultValues<ZodInputTypes>> = {
    modules: [],
    copies: 1,
  }
  const methods = useForm<ZodInputTypes>({
    resolver: zodResolver(boxSchema),
    mode: "onBlur",
    defaultValues,
  })
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid },
  } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: "modules",
  })
  const watchModules = watch("modules")

  const netWeight = watchModules.reduce(
    (acc, m) => acc + m.netWeight * (m.green + m.yellow + m.red),
    0,
  )
  const grossWeight =
    watchModules.reduce((acc, m) => acc + m.netWeight * (m.green + m.yellow + m.red), 0) +
    selectedBoxType.netWeight

  const handleChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event
    fields.forEach((field, index) => {
      if (!value.includes(field.moduleTypeId)) {
        remove(index)
      }
    })
    if (typeof value === "object") {
      value.forEach((newId) => {
        const existingIndex = fields.findIndex((m) => m.moduleTypeId === newId)
        if (existingIndex === -1) {
          const moduleType = moduleTypes.find((m) => m.id === newId)
          if (moduleType !== undefined) {
            append({
              moduleId: newId,
              moduleTypeId: moduleType.id,
              displayName: moduleType.displayName ?? "",
              netWeight: moduleType.defaultNetWeight,
              green: 0,
              yellow: 0,
              red: 0,
            })
          }
        }
      })
    }
  }
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const onSubmit: SubmitHandler<ZodInputTypes> = (data: ZodInputTypes) => {
    addModule({
      variables: {
        input: {
          pickupOrderId: order.id,
          etag: order.etag,
          boxType: {
            displayName: selectedBoxType.displayName,
            internalID: selectedBoxType.id,
          },
          count: data.copies,
          modules: data.modules.map((m) => ({
            moduleType: {
              internalID: m.moduleTypeId,
              displayName: m.displayName,
            },
            green: m.green,
            yellow: m.yellow,
            red: m.red,
            netWeight: m.netWeight,
            fileIDs: [],
          })),
        },
      },
    }).then(() => {
      setAdding(false)
    })
  }

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Stack gap={4}>
            <Alert severity="info">{t("components.AddModule.ModuleLimitDescription")}</Alert>
            <FormControl>
              <InputLabel>{t("components.basics.selectModule.label")}</InputLabel>
              <MuiSelect
                multiple
                endAdornment={
                  <InputAdornment position="start">
                    <Box paddingRight={3}>
                      <IconBox />
                    </Box>
                  </InputAdornment>
                }
                value={fields.map((m) => m.moduleTypeId)}
                renderValue={(selected) => {
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const moduleType = moduleTypes.find((m) => m.id === value)
                        return (
                          <Chip
                            key={value}
                            label={readableEnum(moduleType?.displayName ?? "unknown")}
                          />
                        )
                      })}
                    </Box>
                  )
                }}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    label={t("components.basics.selectModule.label")}
                    data-testid="select-module-type"
                  />
                }
                MenuProps={MenuProps}
              >
                {moduleTypes.map((m) => (
                  <MenuItem
                    key={m.id}
                    value={m.id}
                    className="select-module-type-item"
                    data-testid={`select-module-type-${m.id}`}
                  >
                    <Checkbox checked={fields.find((f) => f.moduleTypeId === m.id) !== undefined} />
                    <ListItemText primary={readableEnum(m.displayName)} />
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
            {fields.map((module, index) => (
              <Module key={module.id} index={index} module={module} />
            ))}
            {watchModules.length > 0 && (
              <>
                <Grid container spacing={2}>
                  <Grid sm={12} md={6}>
                    <TextField
                      disabled
                      label={t("components.basics.netWeight")}
                      fullWidth
                      value={netWeight}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid sm={12} md={6}>
                    <TextField
                      disabled
                      label={t("components.basics.grossWeight")}
                      fullWidth
                      value={grossWeight}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ boxRadius: "4px", padding: "16px" }} bgcolor="background.default">
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontSize={16} component="h2" sx={{ margin: 2 }}>
                      {t("components.AddModule.copies.title")}
                    </Typography>
                    <NumberSelect
                      control={control}
                      setValue={setValue}
                      name="copies"
                      minNumber={1}
                    />
                  </Stack>
                  <RenderError control={control} name="copies" />
                </Box>
              </>
            )}
          </Stack>
        </CardContent>
        <CardActions>
          <CancelButton onClick={() => setAdding(false)} />
          <Button
            type="submit"
            color="secondary"
            data-testid="add-box-submit"
            disabled={!isValid}
            startIcon={<IconPlus />}
          >
            {t("components.AddBox.submit")}
          </Button>
        </CardActions>
      </form>
    </FormProvider>
  )
}
