import { styled } from "@mui/material/styles"
import { Box } from "@northvolt/ui"
import { DataGrid } from "@northvolt/ui/DataGrid"

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  "& .MuiDataGrid-columnHeader": {
    background: `${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 700,
  },
  "& .MuiDataGrid-cell": {
    display: "flex",
    alignItems: "center",
  },
}))

export const OldStyledDataGrid = styled(Box)`
  white-space: break-spaces;
  line-height: 1.43;
`
