import { ModuleGroupSummary } from "@components/PickupOrder/Components/ModuleGroupSummary/ModuleGroupSummary"
import { PackGroupSummary } from "@components/PickupOrder/Components/PackGroupSummary/PackGroupSummary"
import { BoxFragment, OrderFragment } from "@gen/graphql"
import { Stack } from "@northvolt/ui"
import { JSX } from "react"

type ListOrderBoxesProps = {
  stepId: string
  order: OrderFragment
  editable: boolean
}

export const ListOrderBoxes = ({ stepId, order, editable }: ListOrderBoxesProps): JSX.Element => {
  const groupPacks: BoxFragment[][] = []
  order?.boxes.forEach((p) => {
    // TODO: This should be a map instead
    const group = groupPacks.find((gp) => gp[0].group === p.group)
    if (group) {
      group.push(p)
    } else {
      groupPacks.push([p])
    }
  })

  return (
    <Stack gap={2}>
      {order &&
        groupPacks.map((group) =>
          group[0].items[0].__typename === "Pack" ? (
            <PackGroupSummary
              key={group[0].group}
              stepId={stepId}
              order={order}
              boxes={group}
              editable={editable}
            />
          ) : (
            <ModuleGroupSummary
              key={group[0].group}
              stepId={stepId}
              order={order}
              boxes={group}
              editable={editable}
            />
          ),
        )}
    </Stack>
  )
}
