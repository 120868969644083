import {
  BatteryStatus as BatteryStatusType,
  BoxFragment,
  ModuleFragment,
  OrderFragment,
  useDeleteBoxMutation,
} from "@gen/graphql"
import { IconButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Box, Card, Stack, Typography } from "@northvolt/ui"
import { BatteryStatus, ConfirmationDialog, IconBox, IconPen, IconTrashCan } from "@shared"
import { readableEnum } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useState } from "react"
import { useTranslation } from "react-i18next"

type ModuleSummaryProps = {
  order: OrderFragment
  selectedId?: string
  orderBox: BoxFragment
  editable: boolean
}

export const ModuleSummary = ({
  order,
  selectedId,
  orderBox,
  editable,
}: ModuleSummaryProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [deleteBox] = useDeleteBoxMutation()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const handleDelete = (): void => {
    deleteBox({
      variables: {
        input: {
          etag: order.etag,
          pickupOrderId: order.id,
          boxId: orderBox.id,
        },
      },
    })
    setOpenDeleteDialog(false)
  }

  type Pill = [string, string, BatteryStatusType, number]

  const modules: ModuleFragment[] = orderBox.items.filter(
    (p): p is ModuleFragment => p.__typename === "Module",
  )

  const pills: Pill[] = modules.reduce((acc: Pill[], cur) => {
    if (cur.green > 0) {
      acc.push([
        cur.moduleType.id,
        cur.moduleType.displayName,
        BatteryStatusType.Green,
        cur.green,
      ] as Pill)
    }
    if (cur.yellow > 0) {
      acc.push([
        cur.moduleType.id,
        cur.moduleType.displayName,
        BatteryStatusType.Yellow,
        cur.yellow,
      ] as Pill)
    }
    if (cur.red > 0) {
      acc.push([
        cur.moduleType.id,
        cur.moduleType.displayName,
        BatteryStatusType.Red,
        cur.red,
      ] as Pill)
    }
    return acc
  }, [])

  const modulesCount = modules.reduce((acc, cur) => acc + cur.green + cur.yellow + cur.red, 0)

  return (
    <>
      <ModuleCard variant="outlined" sx={{ padding: 1 }} selected={selectedId === orderBox.id}>
        <Box sx={{ p: 1 }}>
          <IconBox />
        </Box>
        <Box flexGrow={1}>
          <Typography>{orderBox.boxType.displayName}</Typography>
          <Typography>{t("components.ModuleSummary.title", { modules: modulesCount })}</Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {pills.map((p) => (
              <BatteryStatus pill status={p[2]} key={p[0] + p[2]} label={readableEnum(p[1])} />
            ))}
          </Stack>
        </Box>
        {editable && (
          <Box sx={{ p: 1 }}>
            <IconButton
              size="small"
              onClick={() => {
                navigate({
                  to: "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit",
                  params: {
                    orderId: order.id,
                    stepId: "2",
                    packageId: orderBox.id,
                    groupId: orderBox.group as string,
                  },
                })
              }}
            >
              <IconPen />
            </IconButton>
          </Box>
        )}
        {editable && (
          <Box>
            <IconButton
              data-testid="module-summary-delete-button"
              size="small"
              onClick={() => setOpenDeleteDialog(true)}
            >
              <IconTrashCan />
            </IconButton>
          </Box>
        )}
      </ModuleCard>
      <ConfirmationDialog
        open={openDeleteDialog}
        title={t("components.ConfirmationDialog.title")}
        confirmButtonText={t("components.ConfirmationDialog.confirm")}
        cancelButtonText={t("components.ConfirmationDialog.cancel")}
        onConfirm={handleDelete}
        onCancel={() => setOpenDeleteDialog(false)}
      ></ConfirmationDialog>
    </>
  )
}

const ModuleCard = styled(Card)<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, selected }) =>
    selected ? theme.palette.background.default : theme.palette.background.paper};
`
