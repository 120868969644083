import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Stack,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import styled from "@mui/system/styled"
import { useNavigate } from "@tanstack/react-router"
import { JSX, MouseEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { IconHamburger, IconUser } from "../../index"
import Northvolt from "../MainLayout/northvolt.svg"
import { Logo } from "./GetImageComponent"

type AppBarProps = {
  toggleOpen: () => void
  logout?: () => void
  logoPath: string | undefined
}

export const AppBar = ({ toggleOpen, logout, logoPath }: AppBarProps): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation("components")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <StyledAppBar sx={{ padding: "0 16px" }}>
      <Toolbar disableGutters sx={{ justifyContent: "space-between", minHeight: "64px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            color="inherit"
            aria-label="Toggle drawer"
            sx={(theme: Theme) => ({
              minHeight: 48,
              justifyContent: "initial",
              p: theme.spacing(2, 2, 2, 1.7), // to make the chevron align with the icons when flipped
              borderRadius: 0, // fixes weird border radius on chevron icons
            })}
            onClick={() => {
              toggleOpen()
            }}
          >
            <IconHamburger />
          </IconButton>
          <Stack
            sx={{ display: "flex", cursor: "pointer" }}
            onClick={() =>
              navigate({
                to: "/",
              })
            }
          >
            <Northvolt />
            <Typography>Revolt</Typography>
          </Stack>
        </Box>
        {logoPath && <Logo imagePath={logoPath} />}
        <Tooltip title={t("AppBar.ProfileMenuTooltip")}>
          <IconButton onClick={handleClick} sx={{ p: 0 }} size="small">
            <IconUser />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ mt: "54px" }}
          id="menu-appbar"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() =>
              navigate({
                to: "/profile",
              })
            }
          >
            <Typography textAlign="center">{t("AppBar.ProfileMenu.profile")}</Typography>
          </MenuItem>
          {logout && (
            <MenuItem onClick={() => logout()}>
              <Typography textAlign="center">{t("AppBar.LogoutMenu.logout")}</Typography>
            </MenuItem>
          )}
        </Menu>
      </Toolbar>
    </StyledAppBar>
  )
}

const StyledAppBar = styled(MuiAppBar)(
  ({ theme }) => `
  background: ${theme.palette.background.paper};
  color: ${theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200]};
`,
)
